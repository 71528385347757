import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { midSubStr } from "_u/string";
import GlobalIcon from "_icon/GlobalIcon";
import cls from "./PeopleItem.module.scss";

const PeopleItem = ({ data, addable, onClick, row, col }) => {
  const isEmpty = !data;
  const { t } = useTranslation();

  const { username, contributionValue } = { ...data };

  const handleClick = (e) => {
    if (isEmpty && !addable) return;

    typeof onClick === "function" && onClick(e, data, row, col);
  };

  let cnt = null;
  if (isEmpty) {
    cnt = (
      <Flex className={clsx(cls.empty, { [cls.canAdd]: addable })}>
        <AddIcon />
      </Flex>
    );
  } else {
    cnt = (
      <Flex className={cls.holder}>
        <Box
          fontSize="3.5vw"
          w="100%"
          textAlign="center"
          borderRadius="5vw"
          borderTopLeftRadius={0}
          borderTopRightRadius={0}
          border="1px solid #bbb"
          m="-1px"
          px="1px"
          boxSizing="content-box"
          bg="#0c9ff680"
          lineHeight={1.8}
        >
          {midSubStr(username, 3, 3)}
        </Box>
        <Box mt={1} flex={1}>
          <GlobalIcon fontSize="8vw" />
        </Box>
        <Box bg="blackAlpha.400" p="0.5vw" pos="absolute" bottom="0" w="100%" lineHeight={1}>
          <Text fontSize="3vw">{t("team.contribution")}</Text>
          <Text fontSize="3vw">{contributionValue || "--"}</Text>
        </Box>
      </Flex>
    );
  }

  return (
    <Box onClick={handleClick} className={clsx(cls.box, { [cls.boxHolder]: !isEmpty })}>
      {cnt}
    </Box>
  );
};

export default PeopleItem;
