import React, { Fragment, useState } from "react";
import { TabList, Tabs, Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import TabBarItem from "./TabBarItem";
import cls from "./Tabbar.module.scss";

// 导航条
function TabBar(props) {
  const { pathname } = useLocation();

  const { tabs, ...rest } = props;

  const tabIdx = tabs.findIndex(
    (e) => e.value === pathname || (e.match && e.match?.includes(pathname)) || pathname.indexOf(e.value) === 0
  );
  const [activeTab] = useState(tabIdx);

  const handleChange = (index) => {
    // if (tabIdx === index) return;
    typeof props.onChange === "function" && props.onChange(tabs[index], index);
  };

  return (
    <Fragment>
      <Box className={cls.tabBar}>
        <Tabs {...rest} index={activeTab} onChange={handleChange}>
          <TabList borderBottom="none">
            {tabs.map((item) => (
              <TabBarItem key={item.value} {...item}>
                {item.label}
              </TabBarItem>
            ))}
          </TabList>
        </Tabs>
      </Box>
    </Fragment>
  );
}

TabBar.displayName = "TabBar";

export default TabBar;
