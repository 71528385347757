/* eslint-disable max-len */
import React from "react";
import { Icon } from "@chakra-ui/react";

const GameIcon = (props) => (
  <Icon viewBox="0 0 1024 1024" {...props}>
    <g fill="currentColor">
      <path d="M546.2016 962.56a450.56 450.56 0 1 1 398.1824-661.1968 35.84 35.84 0 0 1-13.7728 47.8208L648.6016 512l282.0096 162.816a35.84 35.84 0 0 1 13.7728 47.8208A449.7408 449.7408 0 0 1 546.2016 962.56z m0-829.44a378.88 378.88 0 1 0 317.44 585.6256l-304.5376-175.8208a35.84 35.84 0 0 1 0-62.0544l304.4352-175.7184A378.112 378.112 0 0 0 546.2016 133.12z" />
      <path d="M852.7872 512m-55.7056 0a55.7056 55.7056 0 1 0 111.4112 0 55.7056 55.7056 0 1 0-111.4112 0Z" />
    </g>
  </Icon>
);

export default GameIcon;
