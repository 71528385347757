import React, { useState } from "react";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import useRuneTrade from "@/hoc/useRuneTrade";
import { walletAccountAtom } from "@/provider/web3";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";

const Page = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const myAalletAddress = useAtomValue(walletAccountAtom);
  const [creditNum, setCreditNum] = useState(1);
  const [walletAddress, setWalletAddress] = useState("");
  const [repeatWalletAddress, setRepeatWalletAddress] = useState("");
  const { isMinting, mint, mintStatusDisplay } = useRuneTrade(myAalletAddress);

  const handleSubmit = async () => {
    if (!creditNum) {
      toast({ status: "error", title: t("runes.msg_empty_num") });
    } else if (!walletAddress || !repeatWalletAddress) {
      toast({ status: "error", title: t("runes.msg_empty_addr") });
    } else if (walletAddress !== repeatWalletAddress) {
      toast({ status: "error", title: t("runes.msg_addr_err1") });
    } else {
      mint({ number: creditNum, walletAddress });
    }
  };

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <Heading as="h2" size="xl" className={cls.colorful}>
              {t("runes.title")}
            </Heading>
          </Flex>
        </Box>

        <Box className={cls.cnt} bg="gray.800" p="1rem 2rem">
          <FormControl>
            <FormLabel>{t("runes.num")}</FormLabel>
            <NumberInput
              flex={1}
              step={1}
              min={0}
              value={creditNum}
              onChange={(v) => (!v || /^[0-9]\d*$/.test(v)) && setCreditNum(Number(v))}
            >
              <NumberInputField placeholder={t("runes.pld_num")} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {/* <FormHelperText>5 ~ {maxNum}</FormHelperText> */}
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>{t("runes.addr")}</FormLabel>
            <Input
              flex={1}
              value={walletAddress}
              placeholder={t("runes.addr_pld")}
              onChange={(e) => setWalletAddress(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("runes.repeat_addr")}</FormLabel>
            <Input
              flex={1}
              value={repeatWalletAddress}
              placeholder={t("runes.repeat_addr_pld")}
              onChange={(e) => setRepeatWalletAddress(e.target.value)}
            />
            <FormHelperText>{t("runes.addr_tip")}</FormHelperText>
          </FormControl>

          <Button
            isLoading={isMinting}
            isDisabled={isMinting}
            onClick={handleSubmit}
            mt={8}
            borderRadius="full"
            letterSpacing={1}
            width="100%"
            size="lg"
            color="white"
            bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
            loadingText={<Text fontSize="sm">{mintStatusDisplay.substring(0, 12)}...</Text>}
          >
            {t("runes.btn")}
          </Button>

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
