/* eslint-disable max-len */
import React from "react";
import { Icon } from "@chakra-ui/react";

const OrderIcon = (props) => (
  <Icon viewBox="0 0 1024 1024" {...props}>
    <g fill="currentColor">
      <path d="M610.4064 959.0272H405.8624a325.5296 325.5296 0 0 1-325.1712-325.1712V390.144a325.5296 325.5296 0 0 1 325.1712-325.1712h204.8a325.5296 325.5296 0 0 1 324.9152 325.1712v243.712a325.5296 325.5296 0 0 1-325.1712 325.1712zM405.8624 136.6528A253.7984 253.7984 0 0 0 152.3712 390.144v243.712a253.7984 253.7984 0 0 0 253.4912 253.4912h204.8a253.7984 253.7984 0 0 0 253.4912-253.4912V390.144a253.7984 253.7984 0 0 0-253.4912-253.4912z" />
      <path d="M670.4128 419.84h-324.608a35.84 35.84 0 0 1 0-71.68h324.608a35.84 35.84 0 0 1 0 71.68zM577.9456 631.04H345.8048a35.84 35.84 0 0 1 0-71.68h232.1408a35.84 35.84 0 0 1 0 71.68z" />
    </g>
  </Icon>
);

export default OrderIcon;
