import React, { useState } from "react";
import { Box, Button, Flex, FormControl, FormHelperText, FormLabel, Heading, Image, Input, Text, VStack } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import PasswordInput from "_c/PasswordInput";

const Page = () => {
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <Heading as="h2" size="xl">
              新增关联账户
            </Heading>
          </Flex>
        </Box>

        <Box bg="gray.700" p="1.2rem 2rem" pos={"relative"} zIndex={1}>
          <VStack m="xl" bg="gray.800" spacing="4" p="1.5rem 1rem" borderRadius="xl">
            <FormControl mb={2}>
              <FormLabel>关联白名单</FormLabel>
              <Input
                min={6}
                max={15}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="请输入您需关联的账户白名单"
              />
              <FormHelperText>自定义白名单，6-15位数字及字母</FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>关联账户密码</FormLabel>
              <PasswordInput value={pwd} onChange={(e) => setPwd(e.target.value)} placeholder="请输入您需关联的账户密码" />
            </FormControl>

            <Box textAlign="justify" lineHeight={1.6} opacity={0.8} p="0 0.5rem" fontSize="0.85rem">
              <Text>注意：</Text>
              <Text>1.请您确认已关联账户未绑定区块链地址或已完成解绑</Text>
              <Text>2.关联后账户间可方便切换</Text>
              <Text>3.关联账户需使用同一个区块链地址进行绑定</Text>
            </Box>

            <Button w="100%" colorScheme="blue" mt="1rem">
              确认关联并验签绑定
            </Button>
          </VStack>

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
