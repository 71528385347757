import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, FormControl, FormLabel, Heading, Image, Input, VStack, useToast } from "@chakra-ui/react";
import request, { Method } from "_u/request";
import Navbar from "_c/Navbar";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import PasswordInput from "_c/PasswordInput";
import useUserInfo from "@/api/useUserInfo";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";

const Page = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();

  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [rptPwd, setRptPwd] = useState("");
  const [pending, setPending] = useState(false);

  const canSubmit = username && pwd && rptPwd;

  const doSubmit = async () => {
    setPending(true);
    try {
      toast.closeAll();
      if (pwd !== rptPwd) {
        return toast({ title: "两次授权码输入不一致", status: "error" });
      }
      await request("gm/api/user/register", {
        method: Method.POST,
        body: {
          passwd: pwd,
          username,
        },
      });

      toast({ title: "注册成功", status: "success" });
      navigate(-1);
      // 确认返回还是留在当前
    } catch (error) {
      toast({ title: "修改失败: " + error.message, status: "error" });
    }
    setPending(false);
  };

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mt={8}>
            <NavBackIcon />
            <Heading as="h2" size="lg">
              欢迎注册
            </Heading>
          </Flex>
        </Box>

        <Box className={cls.cnt} p={10} pt={2}>
          <Box>
            <FormControl mb={6}>
              <FormLabel>推荐人</FormLabel>
              <Input disabled value={userInfo?.username} />
            </FormControl>
            <FormControl mb={6}>
              <FormLabel>注册白名单</FormLabel>
              <Input
                min={6}
                max={15}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="自定义白名单，8-15位数字及字母"
              />
            </FormControl>
            <FormControl mb={6}>
              <FormLabel>账户授权码</FormLabel>
              <PasswordInput value={pwd} onChange={(e) => setPwd(e.target.value)} placeholder="授权码至少8位，须包含字母与数字" />
            </FormControl>
            <FormControl mb={6}>
              <FormLabel>重复账户授权码</FormLabel>
              <PasswordInput value={rptPwd} onChange={(e) => setRptPwd(e.target.value)} placeholder="请重复输入授权码" />
            </FormControl>
          </Box>

          <Button
            onClick={doSubmit}
            w="100%"
            colorScheme="blue"
            bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
            isDisabled={!canSubmit || pending}
            isLoading={pending}
          >
            注册
          </Button>

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
