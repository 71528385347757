import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const web3Atom = atom(null);
export const walletAccountAtom = atomWithStorage("__WAN_PLAN_WALLET_TEMP_ACCOUNT", null, sessionStorage);
// export const walletAccessTokenAtom = atom(null);
export const walletAccessTokenAtom = atomWithStorage("__WAN_PLAN_WALLET_AUTH_ACCESS_TOKEN", "", sessionStorage);

export const removeAccount = () => {
  sessionStorage.removeItem("__WAN_PLAN_WALLET_TEMP_ACCOUNT");
  sessionStorage.removeItem("__WAN_PLAN_WALLET_AUTH_ACCESS_TOKEN");
};
