import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Box, Center, Flex, Heading, Spinner, Text, VStack, useToast } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import request from "_u/request";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import cls from "./index.module.scss";

const Page = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [detail, setDetail] = useState(null);

  useEffect(() => {
    queryDetail();
  }, [id]);

  const queryDetail = async () => {
    try {
      const data = await request(`gm/api/get_article?articleId=${id}`);
      setDetail(data);
    } catch (error) {
      toast({ title: "Error Occurred!", status: "error" });
    }
  };

  let cnt = (
    <Center>
      <Spinner />
    </Center>
  );
  if (detail) {
    cnt = (
      <Box>
        <Heading mb={4} size="lg" fontWeight="400" lineHeight="1.2">
          {detail.title}
        </Heading>
        <Text mb={4} opacity={0.4}>
          {dayjs(detail.releaseTime).format("YYYY-MM-DD")}
        </Text>
        <div style={{ lineHeight: 1.6, textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: detail.content }} />
        {/* <Text lineHeight={1.6} align="justify">
          Labor creates value, and contributions reap dreams. On this International Labor Day, ASY would like to extend its
          deepest respect and blessings to all community workers. After preparation and complete testing by the technical
          center, the new version 2.0 system will be launched at 23:00 on May 2. The update time is expected to be 1 hour and
          will not affect any functions. This system 2.0 upgrade mainly focuses on four parts: overall UI layout, announcement
          reminder reading, message prompt query and block jumping queue. It is important to note that the block jumping queue
          is an original creation of ASY combined with the function of the block browser, which displays the distribution
          status of each ASY more comprehensively and transparently, providing technical reserves for the launch of the second
          ecology. This system 2.0 upgrade and training courseware version 2.0, in conjunction with the ongoing Hummingbird
          Operation series of plans, demonstrate the strong motivation of the ASY platform to protect the global community and
          realize the 123 strategic guidelines and 333 action plans, allowing us to build a better society with stronger
          action A wonderful common home. ASY official team May 1, 2024
        </Text> */}
      </Box>
    );
  }

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center">
            <NavBackIcon />
            <Heading as="h2" size="md" color="#0c9ff6">
              {t("news_detail.title")}
            </Heading>
          </Flex>
        </Box>
        <Box>
          <Box className={cls.cnt} p={6} pt={0}>
            {cnt}

            <VStack h="60px" />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Page;
