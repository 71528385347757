import React from "react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { Box, Button, Flex, Heading, Image, Link, Icon, SimpleGrid, VStack, Text } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import USDT from "_icon/USDT";
import { ChevronRightIcon } from "@chakra-ui/icons";

const Page = () => {
  const navigate = useNavigate();
  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={4}>
            <NavBackIcon />
            <Heading as="h3" size="md" opacity={0.8}>
              钱包总资产价值
            </Heading>
          </Flex>
          <Heading as="h2" size="2xl" mb={8}>
            $ {Number(1002222).toLocaleString()}
          </Heading>

          <SimpleGrid columns={2} spacing={3}>
            <Link as={ReactRouterLink} to="/otcBuy">
              <Button size="lg" variant="solid" w="100%" colorScheme="blue">
                充值
              </Button>
            </Link>
            <Link as={ReactRouterLink} to="/otcFlash">
              <Button size="lg" variant="outline" w="100%" colorScheme="white">
                提币
              </Button>
            </Link>
          </SimpleGrid>
        </Box>

        <Box className={cls.cnt} bg="gray.600" p="1rem 2rem">
          <VStack spacing={3}>
            <Flex className={cls.entry} bg="gray.800" onClick={() => navigate("/mineOTCRecord?type=CA")}>
              <Icon as={USDT} fontSize="1.8rem" mr={2} />
              <Box flex={1}>CA</Box>
              <Text>$ 80</Text>
              <ChevronRightIcon fontSize="1rem" ml={2} opacity={0.5} />
            </Flex>
            <Flex className={cls.entry} bg="gray.800" onClick={() => navigate("/mineOTCRecord?type=SA")}>
              <Icon as={USDT} fontSize="1.8rem" mr={2} />
              <Box flex={1}>SA</Box>
              <Text>$ 80</Text>
              <ChevronRightIcon fontSize="1rem" ml={2} opacity={0.5} />
            </Flex>
            <Flex className={cls.entry} bg="gray.800" onClick={() => navigate("/mineOTCRecord?type=CA2")}>
              <Icon as={USDT} fontSize="1.8rem" mr={2} />
              <Box flex={1}>CA2</Box>
              <Text>$ 80</Text>
              <ChevronRightIcon fontSize="1rem" ml={2} opacity={0.5} />
            </Flex>
            <Flex className={cls.entry} bg="gray.800">
              <Icon as={USDT} fontSize="1.8rem" mr={2} />
              <Box flex={1}>ASY</Box>
              <Text>80</Text>
            </Flex>
            <Flex className={cls.entry} bg="gray.800" onClick={() => navigate("/mineCoinDetail")}>
              <Icon as={USDT} fontSize="1.8rem" mr={2} />
              <Box flex={1}>USDT</Box>
              <Text>$ 80</Text>
              <ChevronRightIcon fontSize="1rem" ml={2} opacity={0.5} />
            </Flex>
          </VStack>

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
