/* eslint-disable max-len */
import React from "react";
import { Icon } from "@chakra-ui/react";

const WANT = (props) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path
      fill="rgb(205, 127, 50)"
      d="M99.857,0.0 C155.7,0.0 199.714,44.707 199.714,99.857 C199.714,155.7 155.7,199.714 99.857,199.714 C44.707,199.714 0.0,155.7 0.0,99.857 C0.0,44.707 44.707,0.0 99.857,0.0 Z"
    />
    <path
      fill="rgb(255, 255, 255)"
      d="M182.646,121.94 C179.415,123.142 175.813,122.396 175.813,122.396 C175.813,122.396 174.157,121.525 172.234,119.793 C170.311,118.60 145.877,96.39 145.877,96.39 L145.877,104.825 L143.274,104.825 L134.163,91.809 L134.163,84.0 C134.163,84.0 133.715,81.425 137.92,78.793 C140.469,76.162 145.226,78.793 145.226,78.793 L173.210,103.848 L173.210,78.468 L185.249,78.468 L185.249,114.586 C185.249,114.586 185.878,119.46 182.646,121.94 ZM146.203,110.682 L146.203,121.745 L132.536,121.745 L112.687,93.111 L92.188,122.70 L73.966,122.70 C73.966,122.70 71.657,121.478 70.387,120.443 C69.116,119.409 68.109,117.189 68.109,117.189 L54.443,92.460 L39.475,119.142 C39.475,119.142 37.732,122.334 32.641,122.70 C27.551,121.806 26.134,117.840 26.134,117.840 L14.745,78.143 L27.761,78.143 C28.496,78.11 28.737,79.444 28.737,79.444 L35.570,103.848 C35.570,103.848 46.665,82.584 47.935,80.746 C49.204,78.907 50.414,77.494 55.93,77.492 C59.773,77.490 61.601,80.746 61.601,80.746 L74.291,104.499 L81.125,78.143 L94.140,78.143 L85.29,109.380 C85.29,109.380 104.254,82.702 104.878,81.722 C105.503,80.742 107.116,78.24 113.13,77.817 C118.425,77.627 120.822,82.47 120.822,82.47 L140.671,110.682 L146.203,110.682 Z"
    />
  </Icon>
);

export default WANT;
