import React, { useState } from "react";
import { Box, Button, Flex, Heading, Image, Menu, MenuButton, MenuItem, MenuList, VStack } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CheckIcon, ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/otc-bg.jpg";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import CustomInfiniteDataTable from "_c/CustomDataTable/Infinite";
import cls from "./index.module.scss";

const Page = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [type, setType] = useState(searchParams.get("type") || "creditrecord");

  const columns = {
    "": [
      { title: t("otc.col_date_log"), key: "dateLog", align: "left" },
      { title: t("otc.col_credit_num"), key: "creditNum", align: "left" },
      { title: t("otc.col_operation"), key: "operation", align: "left" },
      { title: t("otc.col_comment"), key: "comment", align: "left" },
    ],
    creditrecord: [
      { title: t("otc.col_date_log"), key: "dateLog", align: "left" },
      { title: t("otc.col_credit_num"), key: "creditNum", align: "left" },
      { title: t("otc.col_operation"), key: "operation", align: "left" },
      { title: t("otc.col_comment"), key: "comment", align: "left" },
    ],
    creditnumlog: [
      { title: t("otc.col_dateLog"), key: "dateLog" },
      { title: t("otc.col_left_area_price"), key: "leftAreaPrice" },
      { title: t("otc.col_right_area_price"), key: "rightAreaPrice" },
      { title: t("otc.col_creditAvailable"), key: "creditAvailable" },
      { title: t("otc.col_creditFrozen"), key: "creditFrozen" },
      { title: t("otc.col_lpAmount"), key: "lpAmount" },
      { title: t("otc.col_brokerageLimitAmout"), key: "brokerageLimitAmount" },
      { title: t("otc.col_brokerageLeftAmount"), key: "brokerageLeftAmount" },
      { title: t("otc.col_brokerageRewardTotalAmount"), key: "brokerageRewardTotalAmount" },
      { title: t("otc.col_tokenId"), key: "tokenId" },
    ],
    userrewardlog: [
      { title: t("otc.col_dateLog"), key: "dateLog" },
      { title: t("otc.col_recmdReward"), key: "recmdReward" },
      { title: t("otc.col_coupleReward"), key: "coupleReward" },
      { title: t("otc.col_leadershipReward"), key: "leadershipReward" },
      { title: t("otc.col_structureUpReward"), key: "structureUpReward" },
      { title: t("otc.col_structureDownReward"), key: "structureDownReward" },
      { title: t("otc.col_marketNodeReward"), key: "marketNodeReward" },
      { title: t("otc.col_actualTotalReward"), key: "actualTotalReward" },
    ],
    withdrawlog: [
      { title: t("otc.col_creditNum"), key: "creditNum" },
      { title: t("otc.col_status"), key: "status" },
      { title: t("otc.col_dateLog"), key: "dateLog" },
      { title: t("otc.col_walletAdress"), key: "walletAddress" },
      { title: t("otc.col_feeAmount"), key: "feeAmount" },
    ],
    computingpackage: [
      { title: t("otc.col_orderPackageName"), key: "packageName" },
      { title: t("otc.col_orderBuyAmount"), key: "buyAmount" },
      { title: t("otc.col_orderStatus"), key: "status" },
    ]
  };
  const TITLE = {
    "": t("otc.credit_record"),
    creditrecord: t("otc.credit_record"),
    creditnumlog: t("otc.credit_num_log"),
    userrewardlog: t("otc.user_reward_log"),
    withdrawlog: t("otc.withdraw_log"),
    computingpackage: t("otc.computing_package_order"),
  };

  const column = columns[type];

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <Heading as="h2" size="xl" className={cls.colorful}>
              {t("otc.asset_log")}
            </Heading>
          </Flex>
        </Box>

        <Box className={cls.cnt}>
          <Flex justify="start">
            <Menu>
              <MenuButton
                as={Button}
                bg="gray.800"
                borderRadius="2xl"
                fontWeight="bold"
                fontSize={18}
                borderBottomLeftRadius={0}
                borderBottomRightRadius={0}
                rightIcon={<ChevronDownIcon />}
              >
                {TITLE[type]}
              </MenuButton>
              <MenuList>
                {Object.keys(TITLE)
                  .filter(Boolean)
                  .map((typeKey) => {
                    return (
                      <MenuItem
                        icon={typeKey === type ? <CheckIcon /> : <HamburgerIcon />}
                        key={typeKey}
                        onClick={() => setType(typeKey)}
                      >
                        {TITLE[typeKey]}
                      </MenuItem>
                    );
                  })}
              </MenuList>
            </Menu>
          </Flex>
          <Box bg="gray.700">
            <CustomInfiniteDataTable api={`gm/api/${type || "creditrecord"}/self`} columns={column} />
            <VStack h="60px" />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Page;
