import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import Big from "big.js";

export const LineChart = ({ data, title }) => {
  const chartEl = React.createRef();
  const chart = React.createRef();

  const fixDecimalNumber = (v) => {
    return new Big(new Big(v).mul(1e16).toFixed()).div(1e16).toPrecision();
  };

  useEffect(() => {
    if (chartEl.current && data?.datasets) {
      //   const data = {
      //     labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6"],
      //     datasets: [
      //       {
      //         label: "Dataset",
      //         data: Utils.numbers({ count: 6, min: -100, max: 100 }),
      //         borderColor: Utils.CHART_COLORS.red,
      //         pointStyle: false,
      //       },
      //     ],
      //   };

      const config = {
        type: "line",
        data,
        options: {
          borderWidth: 2,
          responsive: false,
          scales: {
            y: {
              ticks: {
                callback: function (value, index, ticks) {
                  // console.log(value, index, ticks);
                  return fixDecimalNumber(value);
                },
              },
              // beginAtZero: true,
            },
          },
          plugins: {
            title: title
              ? {
                  display: true,
                  align: "start",
                  text: (ctx) => title,
                }
              : undefined,
            legend: false,
            tooltip: {
              enabled: true,
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y;
                  }
                  return label;
                },
              },
            },
          },
        },
      };

      chart.current = new Chart(chartEl.current, config);
    }

    return () => {
      chart.current?.destroy();
    };
  }, [chartEl]);
  return <canvas ref={chartEl} />;
};
