import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { Box, Center, Flex, Spinner, Text, VStack, keyframes } from "@chakra-ui/react";
import { SpinnerIcon } from "@chakra-ui/icons";
import { parse, stringify } from "qs";
import dayjs from "dayjs";
import request from "_u/request";
import DatePicker from "_c/DatePicker";

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;
const spinAni = `${spin} infinite 1s linear`;

/**
 * 无限加载数据列表
 * @param {Object} param
 * @param {String} param.api 接口地址
 * @param {Boolean} param.useDateFilter 使用日期筛选
 * @param {Function} param.renderDataList 列表自定义渲染
 * @param {Function} param.convertResp 自定义接口返回格式
 * @param {Boolean} param.hideEndMessage 隐藏数据量提示
 * @param {Number} [param.pageSize] 分页大小
 * @returns
 */
const InfiniteList = ({ api, renderDataList, convertResp, dataSource, pageSize = 20, useDateFilter = false, hideEndMessage }) => {
  const { t } = useTranslation();
  const [isPending, setIsPending] = useState(true);
  const [dataList, setDataList] = useState(dataSource || []);
  const listElement = useRef();
  const [hasMore, setHasMore] = useState(true);
  const [date, setDate] = useState(useDateFilter ? new Date() : undefined);

  useEffect(() => {
    if (api) {
      fetchHandler(0, date);
    } else {
      setHasMore(false);
      setIsPending(false);
    }
  }, [api, date]);

  /**
   * 列表数据请求封装
   * @param {Function} requester : data requester
   * @param {Number} start : Index to start fetching from or commonaly called 'Offset'
   * @param {Number} end : Last index
   */
  const fetchHandler = async (start) => {
    if (!api) return;

    let offset = start;
    if (offset === undefined) offset = dataList?.length || 0;

    try {
      // api带进来的url参数
      const apiParams = parse(String(api).split("?")[1], {});
      const cleanApi = String(api).split("?")[0];

      setIsPending(true);
      if (offset === 0) setDataList([]);
      // // MOCK
      // let data = null;
      // if (offset < 10) {
      //   data = await delay(500, new Array(20).fill({ creditNum: 20, dateLog: Math.random() }));
      // } else if (offset < 30) {
      //   data = await delay(500, new Array(20).fill({ creditNum: 40, dateLog: Math.random() }));
      // } else {
      //   data = await delay(500, []);
      // }
      const dateLog = date ? dayjs(date).format("YYYY-MM-DD") : undefined;
      const resp = (await request(`${cleanApi}?${stringify({ ...apiParams, limit: pageSize, offset, dateLog })}`)) || [];
      const data = typeof convertResp === "function" ? convertResp(resp) : resp;

      setIsPending(false);
      setDataList([...(offset ? dataList || [] : []), ...(data || [])]);
      setHasMore(data.length === pageSize);
    } catch (error) {
      setIsPending(false);
      setHasMore(false);
      throw new Error(t("components.list.err_fetch_failed") + error.message);
    }
  };

  const refresh = () => {
    fetchHandler(0);
  };

  return (
    <Box w="100%" ref={listElement} overflow="hidden">
      <InfiniteScroll
        dataLength={dataList?.length || 0} // This is important field to render the next data
        next={fetchHandler}
        hasMore={hasMore}
        loader={
          !!dataList?.length && (
            <Box pt={2} align="center">
              <Spinner size="sm" />
            </Box>
          )
        }
        endMessage={
          !hideEndMessage &&
          (!dataList?.length ? null : (
            <Flex justify="center">
              <Text p="2" fontSize="xs" color="gray.400">
                {hasMore ? t("components.list.slide_more") : t("components.list.loaded")}
              </Text>
            </Flex>
          ))
        }
        refreshFunction={refresh}
        pullDownToRefresh
        pullDownToRefreshThreshold={80}
        pullDownToRefreshContent={
          <VStack align="center" h={8}>
            <SpinnerIcon animation={spinAni} boxSize={4} />
          </VStack>
        }
        releaseToRefreshContent={
          <VStack align="center" h={8}>
            <SpinnerIcon animation={spinAni} boxSize={4} />
          </VStack>
        }
      >
        {!!useDateFilter && (
          <Flex justify="flex-end" pb="2">
            <DatePicker value={date} onChange={setDate} max={new Date()} />
          </Flex>
        )}
        {isPending && !dataList.length ? (
          <Center>
            <Spinner></Spinner>
          </Center>
        ) : (
          renderDataList(dataList)
        )}
      </InfiniteScroll>
    </Box>
  );
};

export default InfiniteList;
