import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Box, Heading, Image, Stack, Text, VStack } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import InfiniteList from "_c/InfiniteList";
import { stripHtml } from "_u/string";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";

const Page = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={16} pos={"relative"} zIndex={1}>
          <Stack spacing={0} mb={8} className={cls.colorful}>
            <Heading as="h2" size="3xl" color="#fff">
              {t("news.title")}
            </Heading>
          </Stack>
        </Box>

        <Box>
          <Box className={cls.cnt} p={6} pt={0}>
            <InfiniteList
              api="gm/api/get_article_list"
              convertResp={(v) => v.list}
              renderDataList={(list) => {
                if (!(Array.isArray(list) && list.length)) {
                  return (
                    <Box p={8}>
                      <Text align="center" opacity={0.4}>
                        暂无内容
                      </Text>
                    </Box>
                  );
                }
                return list.map((item, idx) => {
                  return (
                    <Box
                      key={idx}
                      mb={4}
                      p={6}
                      bg="gray.700"
                      borderRadius="xl"
                      onClick={() => navigate(`/newsDetail?id=${item.articleId}`)}
                    >
                      <Heading fontSize="md">{item.title}</Heading>
                      <Text className={cls.desc} mt={2}>
                        {stripHtml(item.content)}
                      </Text>
                      <Text align="right" opacity={0.4} fontSize="12px" mt={2}>
                        {dayjs(item.releaseTime).format("YYYY-MM-DD")}
                      </Text>
                    </Box>
                  );
                });
              }}
            />

            <VStack h="60px" />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Page;
