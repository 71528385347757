/* eslint-disable max-len */
import React from "react";
import { Icon } from "@chakra-ui/react";

const GiftIcon = (props) => (
  <Icon viewBox="0 0 1066 1024" {...props}>
    <g fill="currentColor">
      <path d="M381.184 462.165333L455.936 512H404.821333a42.666667 42.666667 0 1 0 0 85.333333h85.333334v42.666667h-85.333334a42.666667 42.666667 0 1 0 0 85.333333h85.333334v42.666667a42.666667 42.666667 0 1 0 85.333333 0v-42.666667h85.333333a42.666667 42.666667 0 1 0 0-85.333333h-85.333333v-42.666667h85.333333a42.666667 42.666667 0 1 0 0-85.333333h-51.072l74.752-49.834667a42.666667 42.666667 0 0 0-47.36-70.997333l-104.32 69.546667-104.32-69.546667a42.666667 42.666667 0 1 0-47.36 70.997333zM532.821333 42.666667a255.573333 255.573333 0 0 0-199.082666 95.061333 170.666667 170.666667 0 0 0-169.088 289.536 435.626667 435.626667 0 0 0-58.453334 218.837333c0 121.088 49.365333 207.957333 131.498667 262.613334C317.098667 961.536 422.784 981.333333 532.821333 981.333333c110.08 0 215.722667-19.797333 295.168-72.618666 82.133333-54.613333 131.498667-141.525333 131.498667-262.613334 0-79.786667-21.290667-154.581333-58.453333-218.837333a170.666667 170.666667 0 0 0-169.088-289.536A255.573333 255.573333 0 0 0 532.778667 42.666667zM384.896 213.546667A170.581333 170.581333 0 0 1 532.821333 128c63.189333 0 118.4 34.304 147.968 85.546667l22.784 39.466666 37.888-25.344a85.333333 85.333333 0 0 1 82.944 148.608l-47.232 21.717334 30.506667 42.069333a349.781333 349.781333 0 0 1 66.474667 206.037333c0 94.378667-36.565333 153.728-93.44 191.573334C721.237333 877.226667 634.88 896 532.864 896c-102.016 0-188.330667-18.773333-247.893333-58.368-56.832-37.802667-93.44-97.152-93.44-191.573333 0-77.226667 24.746667-148.394667 66.474666-205.994667l30.506667-42.069333-47.232-21.76a85.333333 85.333333 0 1 1 82.944-148.565334l37.930667 25.344 22.784-39.466666z" />
    </g>
  </Icon>
);

export default GiftIcon;
