import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { IconButton, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import React, { useState } from "react";

/**
 * @extends Input
 */
export default function PasswordInput({ left, ...props }) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      {left}
      <Input pr="4.5rem" type={show ? "text" : "password"} {...props} />
      <InputRightElement width="4.5rem">
        <IconButton variant="link" h="1.75rem" icon={show ? <ViewIcon /> : <ViewOffIcon />} onClick={handleClick} />
      </InputRightElement>
    </InputGroup>
  );
}
