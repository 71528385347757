import { useEffect, useState } from "react";
import Big from "big.js";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { postTxHash, queryMintPreCheck } from "@/api/gm";
import { web3Atom } from "@/provider/web3";
import { gmAbi, goldAbi, tradeAbi } from "_cfg/contract";
import { COIN_UNIT } from "_cfg/numeric";
import { NFT_PRICE, NFT_TOKEN_ID_LIST } from "_cfg/nft.config";

/**
 * NFT交易
 * @param {String} walletAddress - 钱包地址
 * @returns {{
 *  mint: ({id: number, count: number, marketNodeId: number}) => Promise,
 *  release: { tokensSold: Array },
 *  assets: { gold: number, tokens: Array },
 *  isMinting:boolean,
 *  mintStatusDisplay: string
 * }}
 */
const useNFTTrade = (walletAddress) => {
  const toast = useToast();
  const { t } = useTranslation();
  const web3Origin = useAtomValue(web3Atom);
  const [tradeCNT, setTradeCNT] = useState(null);
  const [goldCNT, setGoldCNT] = useState(null);
  const [gmCNT, setGmCNT] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [tokensSold, setTokensSold] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [tokens, setTokens] = useState([]);
  const [goldMoney, setGoldMoney] = useState(0); // 代币

  // const [countLimitInfo, setCountLimitInfo] = useState(null);
  const [mintStatusDisplay, setMintStatusDisplay] = useState("");
  const [isMinting, setIsMinting] = useState(false);

  const runMyToast = ({ title, ...restOptions }) => {
    toast({ status: "info", position: "top-right", title, ...restOptions });
    setMintStatusDisplay(title);
  };
  const toastError = (title, rest) => runMyToast({ title, status: "error", ...rest });
  const toastInfo = (title, rest) => runMyToast({ title, status: "info", ...rest });

  useEffect(() => {
    if (web3Origin) initContract();
  }, [web3Origin]);

  /**
   * 初始化合约
   */
  const initContract = () => {
    const tradeCNT = new web3Origin.eth.Contract(tradeAbi.abi, tradeAbi.address); // 交易合约
    setTradeCNT(tradeCNT);
    const goldCNT = new web3Origin.eth.Contract(goldAbi.abi, goldAbi.address); // 代币合约
    setGoldCNT(goldCNT);
    const gmCNT = new web3Origin.eth.Contract(gmAbi.abi, gmAbi.address); // gm NFT合约
    setGmCNT(gmCNT);
  };

  useEffect(() => {
    if (goldCNT && gmCNT) getMoney();
  }, [tradeCNT, goldCNT, gmCNT]);

  /**
   * 确认邀请人信息
   */
  const preCheck = async () => {
    try {
      const canPass = await queryMintPreCheck(walletAddress);
      return !!canPass;
    } catch (error) {
      return false;
      // return true;
    }
  };

  // // 查询个人限额
  // const queryPersonalLimit = async () => {
  //   const info = await tradeCNT?.methods
  //     .getPersonalCount(walletAddress)
  //     .call()
  //     .catch((err) => {
  //       console.log("获取个人额度失败", err);
  //       return {};
  //     })
  //     .then(({ _maxMint, _mintCount }) => {
  //       return {
  //         personalMaxMint: _maxMint, // 单人限额
  //         mintCount: _mintCount, // 当前持有量
  //       };
  //     });
  //   return info;
  // };

  // // 查询合约限制
  // const queryLimit = async () => {
  //   const info = await tradeCNT?.methods
  //     .getPeriodConf()
  //     .call()
  //     .catch((err) => {
  //       console.log("获取本期限额失败", err);
  //       return {};
  //     })
  //     .then(({ _remainCount, _saleCount }) => {
  //       return {
  //         remainCount: _remainCount, // 剩余额度
  //         saleCount: _saleCount, // 发售总量
  //       };
  //     });
  //   return info;
  // };

  // // 检查当前合约的NFT余量
  // const getCurrentRemain = async () => {
  //   let info = null;
  //   try {
  //     // const { personalMaxMint, mintCount } = await queryPersonalLimit();
  //     // const { remainCount, saleCount } = await queryLimit();
  //     const [{ personalMaxMint, mintCount }, { remainCount, saleCount }] = await Promise.all([
  //       queryPersonalLimit(),
  //       queryLimit(),
  //     ]);
  //     info = { personalMaxMint, mintCount, remainCount, saleCount };
  //     console.log("count limit info:", info);
  //     setCountLimitInfo(info);
  //   } catch (error) {
  //     console.log("getCurrentRemain broken: ", error);
  //   }
  //   return info;
  // };

  // 刷新NFT、代币余额
  const getMoney = () => {
    if (!walletAddress) return;
    try {
      // gmCNT?.methods
      //   .balanceOfBatch(
      //     NFT_TOKEN_ID_LIST.map(() => walletAddress),
      //     NFT_TOKEN_ID_LIST
      //   )
      //   .call()
      //   .then((data) => {
      //     console.log("List tokens Of Owner ", walletAddress, " is: ", data);
      //     setTokens(data);
      //   })
      //   .catch((err) => console.warn("Error Getting tokens list", err));
      // Promise.all(NFT_TOKEN_ID_LIST.map((id) => gmCNT.methods.tokenSold(id).call()))
      //   .then((data) => {
      //     console.log("Limit sold info of tokens is: ", data);
      //     setTokensSold(data);
      //   })
      //   .catch((err) => console.warn("Error Getting Limit sold info", err));

      goldCNT?.methods
        .balanceOf(walletAddress)
        .call()
        .then((data) => {
          console.log("GOLD balanceOf ", walletAddress, " is: ", data);
          setGoldMoney(data);
        })
        .catch((err) => console.warn("Error Getting B", err));
    } catch (error) {
      console.error(error);
    }
  };
  // /**
  //  * mint前检查校验
  //  */
  // const mintCheckIsFailed = async (info = countLimitInfo) => {
  //   if (!info) return { title: "请稍后再试", status: "error" };
  //   const { personalMaxMint, mintCount, remainCount } = info || {};
  //   if (Number.isNaN(Number(personalMaxMint)) || Number.isNaN(Number(mintCount)) || Number.isNaN(Number(remainCount))) {
  //     return { title: "网络不佳，建议切换设备代理服务", status: "error" };
  //   }

  //   return false;
  // };

  /**
   * mint NFT
   * @param {Object} params - mint合约参数
   * @param {string|number} params.id - NFT ID
   * @param {number} params.count - 购买数量
   * @returns {Promise<Object>}
   */
  const handleMint = async ({ id: NFTId, count: buyCount, marketNodeId }) => {
    console.log("Ready to mint NFT: ", buyCount, " of id: ", NFT_TOKEN_ID_LIST[NFTId]);
    if (isMinting) return;
    toast.closeAll();
    if (!walletAddress) {
      toastError(t("trade.err_connect_wallet"));
      return;
    }

    if (!buyCount) {
      toastError(t("trade.err_input_num"));
      return;
    }

    setIsMinting(buyCount);

    toastInfo(t("trade.info_cfm_qualify"));

    if (!(await preCheck())) {
      toast.closeAll();
      toastError(t("trade.err_cannot_buy"), { isClosable: true });
      setIsMinting(false);
      return;
    }

    // toastInfo(t("trade.info_cfm_remain"));
    // const info = await getCurrentRemain();
    // const checkFailedMsg = await mintCheckIsFailed(info);
    // if (checkFailedMsg) {
    //   toast.closeAll();
    //   toastInfo("", checkFailedMsg);
    //   setIsMinting(false);
    //   return;
    // }
    console.log("walletAddress", walletAddress);
    try {
      toastInfo(t("trade.info_cfm_permission"));
      const allowance = await goldCNT.methods.allowance(walletAddress, tradeAbi.address).call();
      console.log("allowance", allowance);

      toastInfo(t("trade.info_cfm_limit"));
      // 判断限额是否够用
      // const buyVal = new Big(buyCount).mul(COIN_UNIT).toFixed();
      const readySpend = new Big(buyCount).mul(NFT_PRICE[NFTId]).mul(COIN_UNIT);

      if (readySpend.gt(allowance)) {
        const gasPrice = (await web3Origin.eth.getGasPrice()) || undefined;

        await goldCNT.methods
          .approve(tradeAbi.address, readySpend.toFixed())
          .send({ from: walletAddress, gasPrice })
          .then((data) => {
            // 钱包授权是否失败
            console.log("aft approve seed success", data);
          })
          .catch((err) => {
            console.log("user deny approve seed success", err);
            toastError(err.message);
            throw err;
          });
      }

      console.log("estimateGas...");
      toastInfo(t("trade.info_gas_est"));
      const gasPrice = (await web3Origin.eth.getGasPrice()) || undefined;
      const gasLimit = await tradeCNT.methods
        .MintandBuy(NFT_TOKEN_ID_LIST[NFTId], buyCount, marketNodeId)
        .estimateGas({ from: walletAddress });
      console.log("gas info for buy:(limit, gasPrice)", gasLimit, gasPrice, "GWei");
      console.log("buy:(id, num)", NFT_TOKEN_ID_LIST[NFTId], buyCount, "GWei");
      toastInfo(t("trade.info_ask_confirm"));
      return await tradeCNT.methods
        .MintandBuy(NFT_TOKEN_ID_LIST[NFTId], buyCount, marketNodeId)
        .send({ from: walletAddress, gasPrice, gas: gasLimit })
        .on("transactionHash", function (txhash) {
          // 发起成功， 等待支付结果
          console.log("transactionHash, hash", txhash);
          toast({ title: t("trade.suc_waiting") });
          postTxHash({ txhash });
        })
        .on("error", function (error, redeipt) {
          console.log("trading send error", error, redeipt);
          toastError(t("trade.err_start_fail"));
          setIsMinting(false);
        })
        .then(function (txData) {
          // 交易结果
          console.log("txData", txData);
          toast({ status: "success", title: t("trade.suc_trade") });
          getMoney();
          setIsMinting(false);
        });
    } catch (error) {
      console.log("mint error", error);
      let msg = error.message;
      const [err, data] = error.message.split("Internal JSON-RPC error.\n");
      msg = err.substring(0, 0) + "...";
      try {
        if (data && data[0] === "{") {
          const errInfo = JSON.parse(data);
          msg = errInfo?.message || "";
          const [raw, detail] = msg.split("Error:");
          msg = detail || raw;
        }
      } catch (error) {
        console.log("message cannot parse");
        msg = "Internal JSON-RPC error";
      }
      toastError(t("trade.err_mint_fail") + msg);
      setIsMinting(false);
      throw error;
    }
  };

  return {
    // 持有资产信息
    assets: { gold: goldMoney, tokens },
    // 发行信息
    release: { tokensSold },
    mint: handleMint,
    isMinting,
    mintStatusDisplay,
  };
};

export default useNFTTrade;
