import React from "react";
import CustomInfiniteDataTable from "_c/CustomDataTable/Infinite";
import { useTranslation } from "react-i18next";

const UnSettledList = ({ operation, ...props }) => {
  const { t } = useTranslation();

  const columns = [
    { title: t("team.col_username"), key: "username", align: "left" },
    { title: t("team.col_token"), key: "tokenId", align: "left" },
  ];
  if (operation) {
    columns.push(operation);
  }

  return (
    <CustomInfiniteDataTable
      api="gm/api/user/team?forWhat=ForBind" // 待安置
      // dataSource={[{ username: "A**x", main: 1000, potential: 200, package: 600 }]}
      columns={columns}
    />
  );
};

export default UnSettledList;
