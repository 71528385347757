import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, Flex, Heading, Image, FormControl, FormLabel, VStack, Text } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import CoinInput, { COIN_TYPE } from "_c/CoinInput";

const Page = () => {
  const [num, setNum] = useState();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const isWithdraw = type === "withdraw";

  const TITLE = {
    deposit: "充值",
    withdraw: "提币",
  };

  const title = TITLE[type];

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <Heading as="h2" size="2xl" ml={2}>
              {title}
            </Heading>
          </Flex>
        </Box>

        <Box className={cls.cnt} bg="gray.800" p="1rem 2rem">
          <Box>
            <FormControl mb={2}>
              <FormLabel>请输入{title}数量</FormLabel>
              <CoinInput
                coinType={COIN_TYPE.USDT}
                value={num}
                onChange={setNum}
                balanceLabel={isWithdraw ? "当前可提取数量" : undefined}
              />
            </FormControl>
            {isWithdraw && (
              <Box opacity={0.6}>
                <Text>* 提示：</Text>
                <Text>单笔币提将承诺提供0USDT燃料（GWEI）</Text>
              </Box>
            )}

            <VStack mt={6} spacing={4}>
              {isWithdraw ? (
                <Button w="100%" size="lg" colorScheme="blue">
                  提币
                </Button>
              ) : (
                <Button w="100%" size="lg" colorScheme="blue">
                  授权
                </Button>
              )}
              <Button w="100%" size="lg" onClick={() => navigate(-1)}>
                返回
              </Button>
            </VStack>
          </Box>
        </Box>
        <VStack h="60px" />
      </Box>
    </div>
  );
};

export default Page;
