/* eslint-disable max-len */
import React from "react";
import { Icon } from "@chakra-ui/react";

const DMMC = (props) => (
  <Icon viewBox="-22 -15 256 256" {...props}>
    <linearGradient id="colorful" x1="50%" x2="0%" y1="0%" y2="86.603%">
      <stop offset="0" stopColor="#00a8ff" />
      <stop offset=".33" stopColor="#00a8ff" />
      <stop offset=".48" stopColor="#3c8af8" />
      <stop offset=".83" stopColor="#776bf0" />
      <stop offset="1" stopColor="#9c74ff" />
    </linearGradient>
    <path
      d="m216.481 122.248c-4.511 44.109-32.504 83.72-81.428 99.388-15.544 4.978-35.601 3.976-55.61 3.976-25.817 0-52.364 0-77.457 0 0-9.785-1.076-27.044-.993-38.762h80.436c13.999.002 30.471 1.65 41.707-1.987 32.368-10.478 52.347-32.248 55.61-70.566 1.653-19.405-8.131-35.794-12.909-43.731-7.672-12.744-20.318-22.437-39.722-28.823-12.743-4.194-29.627-2.982-45.679-2.981-26.478 0-52.965 0-79.443 0 0-12.92 0-25.843 0-38.762h84.408c18.665.011 40.403.892 52.028 5.249 31.945 11.973 51.482 28.071 66.143 55.378 6.259 11.659 15.274 38.506 12.909 61.621zm-181.725-65.597c24.493 0 43.025-.778 64.547-.993 15.479.379 20.193 2.65 25.819 3.975 11.963 5.06 26.344 16.357 29.791 29.817-33.098 0-66.205 0-99.303 0 0-3.976 0-7.952 0-11.927-18.204 0-36.413 0-54.617 0 0-6.586-.077-13.167 0-19.878 5.605 0 26.757-.994 33.763-.994zm-32.77 46.713h48.659c3.957 0 72.826-.994 109.233-.994.331.331.663 8.614.994 8.945 0 3.75.323 4.069 0 10.933-52.957 0-105.929.994-158.886.994 0-7.269 0-20.034 0-19.878zm53.624 47.706c0-3.975 0-8.945 0-12.92h99.303v1.988c-11.076 11.595-13.777 20.712-30.784 27.829-9.924 4.152-22.523 1.038-35.749 2.981-29.457 0-58.923 0-88.38 0 0-6.625 0-13.252 0-19.878h1.986c6.109 0 43.355-.109 53.624 0z"
      fill="url(#colorful)"
      fillRule="evenodd"
    />
  </Icon>
);

export default DMMC;
