import React from "react";
import clsx from "clsx";
import stl from "./index.module.scss";
import { Box, Text, HStack, Icon, Input, VStack, Center, useColorModeValue, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ETH from "_icon/ETH";
import USDT from "_icon/USDT";
import WANT from "_icon/WANT";
import BNB from "_icon/BNB";
import BUSD from "_icon/BUSD";

const COIN_MAP = {
  ETH,
  USDT,
  BNB,
  BUSD,
  WANT,
};

/**
 * 币类型
 */
export const COIN_TYPE = {
  ETH: "ETH",
  USDT: "USDT",
  BNB: "BNB",
  BUSD: "BUSD",
  WANT: "WANT",
};

/**
 * 代币金额输入
 * @param {object} props 属性
 * @param {COIN_TYPE} props.coinType 币类型
 * @param {string | number} props.value 数值
 * @param {number} props.balance 余额
 * @param {(type: COIN_TYPE) => void} props.onCoinTypeChange 类型点击切换
 * @param {boolean} props.enableMaxBalance 启用最大值交互提示
 * @param {boolean} props.enableSelect 启用币种选择
 * @param {boolean} props.enableAssess 启用价值评估
 * @returns {Component}
 */
export function CoinInput(props) {
  const {
    className,
    coinIcon,
    coinType = COIN_TYPE.WANT,
    value = "",
    onChange,
    placeholder = "0.00",
    disabled,
    balance = 0,
    enableSelect,
    enableAssess,
    enableMaxBalance,
    readonly,
    pattern,
    autoFocus,
    balanceLabel = "余额",
    hideBalance = false,
  } = props;
  const type = `${coinType || ""}`.toUpperCase();
  const CoinIcon = COIN_MAP[type];

  const handleMaxClick = () => {
    if (!enableMaxBalance) return;
    handleChange(balance);
  };

  const handleChange = (e) => {
    const { defaultZero = 0 } = props;
    const val = e.target ? e.target.value : e || defaultZero;

    typeof onChange === "function" && onChange(val);
  };

  return (
    <Box
      className={clsx("coin-input", className, stl.wrap, "ft-play")}
      bg={useColorModeValue("gray.50", "blackAlpha.100")}
      borderRadius="2xl"
      _hover={{
        borderColor: useColorModeValue("gray.300", "gray.700"),
      }}
    >
      <VStack>
        <HStack width="full" justify="space-between" padding="4" paddingBottom={0}>
          <Input
            className={stl.input}
            fontSize="3xl"
            fontWeight="bold"
            _placeholder={{ fontWeight: "bold" }}
            variant="unstyled"
            type="number"
            autoComplete="off"
            onChange={handleChange}
            value={value}
            placeholder={placeholder}
            pattern={pattern || /[^\d+]/}
            disabled={disabled}
            readOnly={readonly}
            autoFocus={autoFocus}
          />

          <HStack>
            <Center
              _hover={{
                background: useColorModeValue("gray.200", "blackAlpha.300"),
                cursor: enableSelect ? "pointer" : "default",
              }}
              borderRadius="xl"
              background={useColorModeValue("gray.100", "blackAlpha.100")}
              fontWeight="bold"
              paddingInline={2.5}
              paddingBlock={2}
            >
              {coinIcon || <Icon as={CoinIcon} width={6} height={6} />}
              <Text fontSize="lg" paddingInline={2} whiteSpace="nowrap">
                {type}
              </Text>
              {!!enableSelect && <Icon as={ChevronDownIcon} width={6} height={6} />}
            </Center>
          </HStack>
        </HStack>
        <HStack
          width="full"
          justify="space-between"
          padding="4"
          paddingTop={0}
          style={{ marginTop: 0 }}
          fontSize="md"
          color={useColorModeValue("blackAlpha.500", "whiteAlpha.500")}
        >
          <Box>{!!enableAssess && "预估现金价值"}</Box>
          {enableMaxBalance ? (
            <Center onClick={handleMaxClick} cursor={"pointer"}>
              {!hideBalance && (
                <Text>
                  {balanceLabel}：{balance}
                </Text>
              )}
              {!(Number(balance) >= Number(value)) && (
                <Button ml="2" size="xs" colorScheme="red" opacity={0.8}>
                  最大值
                </Button>
              )}
            </Center>
          ) : (
            !hideBalance && (
              <Center>
                <Text>
                  {balanceLabel}：{balance}
                </Text>
              </Center>
            )
          )}
        </HStack>
      </VStack>
    </Box>
  );
}

export default CoinInput;
