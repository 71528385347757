import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, Heading, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import UnSettledList from "./UnSettledList";

/**
 *
 * @param {Object} props
 * @param {Object} props.onSelect 选择
 * @param {boolean} props.selectable 是否可以选择
 * @param {boolean} props.isManual 是否手动选区
 * @returns
 */
const UnSettledModal = ({ onClose, isOpen, onSelect, selectable, isManual }) => {
  console.log(selectable);

  const { t } = useTranslation();

  const operation = {
    title: t("team.col_operation"),
    align: "left",
    render: (item) => {
      return (
        <Button
          onClick={() => {
            onSelect(item);
            onClose();
          }}
          variant="outline"
          colorScheme="blue"
          size="xs"
        >
          {t("team.col_opr_select")}
        </Button>
      );
    },
  };

  const operationAuto = {
    title: t("team.col_operation"),
    align: "left",
    render: (item) => {
      return (
        <ButtonGroup gap={3} justifyContent="space-between" w="100%">
          <Button
            onClick={() => {
              onSelect(item, "AutoLeft");
              onClose();
            }}
            variant="outline"
            size="xs"
            colorScheme="blue"
          >
            {t("team.col_opr_left")}
          </Button>

          <Button
            onClick={() => {
              onSelect(item, "AutoRight");
              onClose();
            }}
            variant="outline"
            size="xs"
            colorScheme="blue"
          >
            {t("team.col_opr_right")}
          </Button>
        </ButtonGroup>
      );
    },
  };

  let opr;
  if (selectable) {
    opr = isManual ? operation : operationAuto;
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent w="90vw">
        <ModalHeader>
          <Heading fontSize="2xl">{t("team.pending_list")}</Heading>
        </ModalHeader>
        <ModalBody pb={4}>
          <Box mx={-3} p={2} px={0}>
            <UnSettledList operation={opr} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UnSettledModal;
