import Web3 from "web3";
import Web3Modal from "web3modal";
import { BSC_NETWORK } from "_cfg/network";

const providerOptions = {};

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
});

export const connectWallet = async ({ chainId, onConnect, onChainChanged, onAccountsChanged, onDisconnect }) => {
  web3Modal.on("connect", onConnect);
  const provider = await web3Modal.connect();
  provider.on("disconnect", onDisconnect);
  provider.on("chainChanged", onChainChanged);
  provider.on("accountsChanged", onAccountsChanged);
  return new Web3(provider);
};

export const clearCachedProvider = () => {
  web3Modal?.clearCachedProvider?.();
};

/**
 * 切换或添加BSC网络到钱包工具
 * @param {*} provider
 */
export const addAndConnectNetwork = async (provider) => {
  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: BSC_NETWORK.chainId }],
    });
  } catch (switchError) {
    console.log("Switch Failed", switchError);
    // This error code indicates that the chain has not been added to MetaMask
    // PC | Mobile
    if (switchError.code === 4902 || switchError.code === -32603) {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [BSC_NETWORK],
        });
      } catch (addError) {
        throw new Error(addError.message);
      }
    } else {
      if (switchError.code === -32002) {
        throw new Error("切换操作已申请，请在钱包(MetaMask)中确认");
      } else {
        // handle other "switch" errors
        throw new Error("switch failed: " + switchError.message);
      }
    }
  }
};

/**
 * 钱包地址掩码展示
 * @param {String} address - 钱包地址
 */
export const maskWalletAddress = (address) => `${address.substring(0, 4)}....${address.substring(address.length - 6)}`;
