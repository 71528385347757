export const API_ROOT = process.env.REACT_APP_API || "/api/";
export const IMG_ROOT = process.env.REACT_APP_IMG || "/images/finance/";
export const IDP_SERVER = process.env.REACT_APP_IDP || "/login";

export const FULL_API_ROOT = /^http(|s):\/\//.test(API_ROOT) ? API_ROOT : location.origin + API_ROOT;

/**
 * 路由前缀基准路径
 */
export const APP_ROOT_BASE_NAME = process.env.PUBLIC_URL || "/";

/**
 * 页面根地址
 */
export const APP_ROOT = window.location.origin + APP_ROOT_BASE_NAME;

/**
 * 当前环境是否为测试环境
 */
// eslint-disable-next-line no-undef
export const CURRENT_IS_TEST = window.location.search.indexOf("is_test=1") > -1 || process.env.NODE_ENV !== "production" || false;
