import React from "react";
import { Box, Flex, Heading, Image, VStack } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/otc-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import CustomInfiniteDataTable from "_c/CustomDataTable/Infinite";

const Page = () => {
  const columns = [
    { title: "时间", key: "time", align: "right" },
    { title: "批次", key: "batch", isNumeric: true, align: "left" },
    { title: "倍率", align: "right", key: "rate" },
    { title: "数量", align: "right", key: "number" },
  ];

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <Heading as="h2" size="xl" color="#fff">
              分叉记录
            </Heading>
          </Flex>
        </Box>

        <Box className={cls.cnt} bg="gray.700">
          <CustomInfiniteDataTable api={`/gm/api/tradeorder/hallself"}`} columns={columns} />
          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
