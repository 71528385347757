import React from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/otc-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";

const Page = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mt={8}>
            <NavBackIcon />
            <Heading as="h2" size="lg">
              欢迎注册
            </Heading>
          </Flex>

          <SimpleGrid mt={6} columns={2} spacing={3}>
            <Link href="/mineAddSub">
              <Button size="lg" variant="solid" w="100%" colorScheme="blue">
                新增子账户
              </Button>
            </Link>
            <Button size="lg" variant="outline" w="100%" colorScheme="white" onClick={onOpen}>
              一键归集
            </Button>
          </SimpleGrid>
        </Box>
        <Box bg="gray.600">{/* 子账户列表 */}</Box>
        <VStack h="60px" />
      </Box>

      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent w="85vw">
          <ModalHeader>
            <Heading fontSize="2xl">一键归集</Heading>
          </ModalHeader>
          <ModalBody>
            <Box bg="gray.800" p={6} color="orange">
              一键归集将归集您的子账户中的所有CA和SA，全部进入您的主账户余额
            </Box>
            <HStack my={6} justify="space-between">
              <Button w="100%" onClick={onClose}>
                取消
              </Button>
              <Button variant="solid" colorScheme="blue" w="100%" onClick={onClose}>
                确定
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Page;
