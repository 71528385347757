import React, { useState } from "react";
import { Box, Button, Flex, FormControl, FormLabel, Heading, Image, Input, VStack } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import PasswordInput from "_c/PasswordInput";

const Page = () => {
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [rptPwd, setRptPwd] = useState("");

  const canSubmit = username && pwd && rptPwd;

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mt={8}>
            <NavBackIcon />
            <Heading as="h2" size="lg">
              重置您的密码
            </Heading>
          </Flex>
        </Box>

        <Box className={cls.cnt} p={10} pt={2}>
          <Box>
            <FormControl mb={6}>
              <FormLabel>注册白名单</FormLabel>
              <Input value={username} onChange={(e) => setUsername(e.target.value)} placeholder="请输入当前授权码" />
            </FormControl>
            <FormControl mb={6}>
              <FormLabel>新账户密码</FormLabel>
              <PasswordInput value={pwd} onChange={(e) => setPwd(e.target.value)} placeholder="密码至少6位，须包含字母与数字" />
            </FormControl>
            <FormControl mb={6}>
              <FormLabel>重复新账户密码</FormLabel>
              <PasswordInput value={rptPwd} onChange={(e) => setRptPwd(e.target.value)} placeholder="请重复输入密码" />
            </FormControl>
          </Box>

          <Button w="100%" colorScheme="blue" bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)" isDisabled={!canSubmit}>
            身份验证并重置密码
          </Button>

          <Box mt={6} opacity={0.8} textAlign="justify" fontSize="0.8rem">
            注意：请使用此账户绑定的区块链地址进行验签确认身份，未绑定区块链地址的账户无法进行忘记密码操作
          </Box>

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
