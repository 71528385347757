/**
 * 字符串中间部分使用...截取
 * @param {String} str 字符串
 * @param {number} preLen 前半部分长度
 * @param {number} tailLen 后半部分长度
 * @returns {String} abcd...t12345
 */
export const midSubStr = (str, preLen = 4, tailLen = 6) => {
  if (typeof str !== "string") return "";
  if (str.length <= preLen + tailLen) return str;
  return `${str.substring(0, preLen)}...${str.substring(str.length - tailLen)}`;
};

export const stripHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};
