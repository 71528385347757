import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import copy from "copy-to-clipboard";
import { useAtom } from "jotai";
import {
  Button,
  IconButton,
  useDisclosure,
  Text,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  Divider,
  useColorModeValue,
  useToast,
  Box,
} from "@chakra-ui/react";
import withWalletConnect from "@/hoc/withWalletConnect";
import WalletIcon from "@/image/icon/WalletIcon";
import { CopyIcon, ExternalLinkIcon, WarningIcon } from "@chakra-ui/icons";
import { addAndConnectNetwork, maskWalletAddress } from "_u/web3Util";
import { walletAuthLogin, walletAuthLogout } from "_u/web3Login";
import { walletAccessTokenAtom } from "@/provider/web3";
import MetaMaskAvatar from "../MetaMaskAvatar";

function WalletCapsule(props) {
  const toast = useToast();
  const { t } = useTranslation();
  const { web3, walletAccount, onClick, unsupported, autoLogin } = props;
  const [walletAccessToken, setWalletAccessToken] = useAtom(walletAccessTokenAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loginPending, setLoginPending] = useState(false);
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("inviteCode");
  const whiteColor = useColorModeValue("white");

  // const orangeColor = useColorModeValue("orange");

  useEffect(() => {
    if (web3 && autoLogin) handleAuthLogin();
  }, [web3, walletAccount, walletAccessTokenAtom]);

  const handleAuthLogin = async () => {
    if (loginPending) return;
    if (walletAccount && walletAccessToken && walletAccessToken !== "REFRESH") return;
    if (walletAccount) {
      setLoginPending(true);
      try {
        const needReloadPage = walletAccessToken === "REFRESH";
        const { accessToken } = await walletAuthLogin(web3, walletAccount, { inviteCode });
        setWalletAccessToken(accessToken);
        console.log(needReloadPage);
        setTimeout(() => {
          needReloadPage && location.reload();
        }, 100);
      } catch (error) {
        console.warn(error.message);
        toast({
          title: t("wallet.login_failed"),
          description: error.message || t("wallet.failed_unable"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      setLoginPending(false);
    } else {
      walletAuthLogout();
      setWalletAccessToken("");
    }
  };

  const handleToggleChain = () => {
    web3.currentProvider && addAndConnectNetwork(web3.currentProvider);
  };

  if (unsupported) {
    return (
      <Button onClick={handleToggleChain} leftIcon={<WarningIcon />}>
        UNSUPPORTED
      </Button>
    );
  }

  if (!(web3 && walletAccount && typeof walletAccount === "string")) {
    return (
      <IconButton borderRadius="2xl" borderWidth="1px" onClick={onClick} borderColor={whiteColor} icon={<WalletIcon />}>
        {t("wallet.connect")}
      </IconButton>
    );
  }
  const maskedAddr = maskWalletAddress(walletAccount);

  return (
    <Fragment>
      <Button
        p="2"
        borderRadius="3xl"
        borderWidth="1px"
        onClick={onOpen}
        // colorScheme="orange"
        // borderColor={orangeColor}
      >
        <MetaMaskAvatar account={walletAccount} size={22} />
        <Text className="wallet-capsule-addr" ml="2" fontSize="lg">
          {maskedAddr}
        </Text>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="md">
          <ModalHeader>
            <HStack align="center">
              <WalletIcon />
              <Text>{t("wallet.account")}</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {/* <HStack justify="space-between">
                <Text />

                <ButtonGroup variant="outline" size="xs" colorScheme="red">
                  <Button disabled>断开</Button>
                  <Button disabled>更换</Button>
                </ButtonGroup>
              </HStack> */}
              <HStack marginBlock="4" align="center">
                <MetaMaskAvatar account={walletAccount} size={22} />
                <Text fontSize="xl" fontWeight="bold">
                  {maskedAddr}
                </Text>
              </HStack>
              <Button
                variant="link"
                color="blue.400"
                leftIcon={<CopyIcon color="blue.400" />}
                onClick={() => copy(String(walletAccount))}
                mr={4}
              >
                {t("wallet.copy_addr")}
              </Button>
              <Button
                as="a"
                float="right"
                target="_blank"
                variant="link"
                rel="noopener noreferrer"
                href={`https://bscscan.com/address/${walletAccount}`}
              >
                <ExternalLinkIcon />
                {t("wallet.browser_expo")}
              </Button>
            </Box>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Text opacity={0.4}>{t("wallet.foot")}</Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
}
export default withWalletConnect()(WalletCapsule);
