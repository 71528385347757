/**
 * NFT价格，游标即id
 */
// export const NFT_PRICE = [140, 390, 700, 1400];
export const NFT_PRICE = [140, 420, 700, 1400];

/**
 * NFT IDs
 */
// export const NFT_TOKEN_ID_LIST = [140, 390, 700, 1400];
export const NFT_TOKEN_ID_LIST = [140, 420, 700, 1400];
/**
 * NFT 类型标签
 */
// export const NFT_TOKEN_LABEL_LIST = ["D1(140)", "D2(390)", "D3(700)", "D4(1400)"];
export const NFT_TOKEN_LABEL_LIST = ["D1(140)", "D4(1400)"];

/**
 * Runes单价
 */
export const RUNES_PRICE = 0.005; // BNB 0.01GWei
