/* eslint-disable max-len */
import React from "react";
import { Icon } from "@chakra-ui/react";

const BagIcon = (props) => (
  <Icon viewBox="0 0 1066 1024" {...props}>
    <g fill="currentColor">
      <path d="M362.154667 42.666667a128 128 0 0 0-128 128v42.666666h-85.333334a85.333333 85.333333 0 0 0-85.333333 85.333334v597.333333a85.333333 85.333333 0 0 0 85.333333 85.333333h768a85.333333 85.333333 0 0 0 85.333334-85.333333V298.666667a85.333333 85.333333 0 0 0-85.333334-85.333334h-85.333333V170.666667a128 128 0 0 0-128-128h-341.333333z m384 170.666666h-426.666667V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667h341.333333a42.666667 42.666667 0 0 1 42.666667 42.666667v42.666666z m-597.333334 85.333334h768v128h-768V298.666667z m0 213.333333h768v384h-768v-384z m341.333334 42.666667a42.666667 42.666667 0 1 0 0 85.333333h85.333333a42.666667 42.666667 0 1 0 0-85.333333h-85.333333z" />
    </g>
  </Icon>
);

export default BagIcon;
