import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import request, { Method } from "_u/request";
// import NFTTrade from "./NFTTrade";

const Page = () => {
  const MIN_NUM = 5 + 1;
  const { t } = useTranslation();
  const toast = useToast();
  const [creditNum, setCreditNum] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [repeatWalletAddress, setRepeatWalletAddress] = useState("");
  const [pending, setPending] = useState(false);
  const [maxNum, setMaxNum] = useState(0);
  const [minNum, setMinNum] = useState(0);

  useEffect(() => {
    request("gm/api/user/selfassets").then((data) => {
      setMaxNum(parseInt(data.creditAvailable));

      if (maxNum > 0) {
        setMinNum(MIN_NUM);
        setCreditNum(MIN_NUM);
      } else {
        setMinNum(0);
      }
    });
  }, []);

  const handleSubmit = async () => {
    if (pending) return;
    setPending(true);
    try {
      if (!creditNum) {
        toast({ status: "error", title: t("transfer_out.msg_empty_num") });
      } else if (maxNum - creditNum < 0) {
        toast({ status: "error", title: t("transfer_out.msg_num_over") });
      } else if (!walletAddress || !repeatWalletAddress) {
        toast({ status: "error", title: t("transfer_out.msg_empty_addr") });
      } else if (walletAddress !== repeatWalletAddress) {
        toast({ status: "error", title: t("transfer_out.msg_addr_err1") });
      } else {
        await request("gm/api/withdraw/apply", {
          method: Method.POST,
          body: {
            creditNum,
            walletAddress,
          },
        });
        toast({ status: "success", title: t("transfer_out.msg_suc") });
      }
    } catch (error) {
      toast({ status: "success", title: t("transfer_out.msg_err") + " " + error.message });
    }
    setPending(false);
  };

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <Heading as="h2" size="xl" className={cls.colorful}>
              {t("transfer_out.title")}
            </Heading>
          </Flex>
        </Box>

        <Box className={cls.cnt} bg="gray.800" p="1rem 2rem">
          <FormControl>
            <FormLabel>
              {t("transfer_out.num")}({maxNum ? ` ${minNum} ~ ${maxNum}` : ""}USDT)
            </FormLabel>
            <NumberInput
              flex={1}
              step={1}
              min={minNum}
              max={maxNum}
              value={creditNum}
              onChange={(v) => (!v || /^[0-9]\d*$/.test(v)) && setCreditNum(Number(v))}
            >
              <NumberInputField placeholder={t("trade.pld_num")} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {/* <FormHelperText>5 ~ {maxNum}</FormHelperText> */}
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>{t("transfer_out.addr")}</FormLabel>
            <Input
              flex={1}
              value={walletAddress}
              placeholder={t("transfer_out.addr_pld")}
              onChange={(e) => setWalletAddress(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("transfer_out.repeat_addr")}</FormLabel>
            <Input
              flex={1}
              value={repeatWalletAddress}
              placeholder={t("transfer_out.repeat_addr_pld")}
              onChange={(e) => setRepeatWalletAddress(e.target.value)}
            />
            <FormHelperText>{t("transfer_out.addr_tip")}</FormHelperText>
          </FormControl>

          <Button
            isLoading={pending}
            isDisabled={pending || !maxNum}
            onClick={handleSubmit}
            mt={8}
            borderRadius="full"
            letterSpacing={1}
            width="100%"
            size="lg"
            color="white"
            bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
            loadingText={<Text fontSize="sm">{t("transfer_out.submitting")}</Text>}
          >
            {t("transfer_out.btn")}
          </Button>

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
