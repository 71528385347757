import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Stat,
  StatGroup,
  StatHelpText,
  StatNumber,
  Text,
  VStack,
} from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/otc-bg.jpg";
import cls from "./index.module.scss";

const HomePage = () => {
  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Heading as="h3" size="md" mb={3}>
            竞购总额
          </Heading>
          <Heading as="h2" size="2xl" mb={8}>
            $ {Number(1002222).toLocaleString()}
          </Heading>
          <SimpleGrid columns={3} spacing={3}>
            <Link as={ReactRouterLink} to="/otcBuy">
              <Button variant="solid" w="100%" colorScheme="blue">
                竞购
              </Button>
            </Link>
            <Link as={ReactRouterLink} to="/otcFlash">
              <Button variant="solid" w="100%" colorScheme="blue">
                闪兑
              </Button>
            </Link>
            <Link as={ReactRouterLink} to="/mineOTCRecord?type=OTC">
              <Button variant="outline" w="100%" colorScheme="white">
                交易记录
              </Button>
            </Link>
          </SimpleGrid>
        </Box>

        <Box className={cls.cnt} bg="gray.600" p="1rem 2rem">
          {new Array(20).fill({ orderNo: "09151656330", total: 55000, bought: 15000, pending: 30000 }).map((ele, index) => {
            return (
              <Box key={index} p="3" borderRadius="xl" bg="gray.700" mb="3">
                <Text fontSize="sm" pt={0}>
                  订单编号：#{ele.orderNo}
                </Text>
                <StatGroup borderRadius="md" mt={2} textAlign="center" columns={3} bg="gray.800" p={1}>
                  <Stat>
                    <StatNumber>{ele.total}</StatNumber>
                    <StatHelpText>买入总量</StatHelpText>
                  </Stat>
                  <Stat>
                    <StatNumber>{ele.bought}</StatNumber>
                    <StatHelpText>成交总量</StatHelpText>
                  </Stat>
                  <Stat>
                    <StatNumber>{ele.pending}</StatNumber>
                    <StatHelpText>待成交</StatHelpText>
                  </Stat>
                </StatGroup>
              </Box>
            );
          })}

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default HomePage;
