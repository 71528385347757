import React from "react";
import {
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import ReactMobileDatepicker from "react-mobile-datepicker";
import { CalendarIcon, SmallCloseIcon } from "@chakra-ui/icons";

const DatePicker = ({ value, onChange, placeholder = "选择日期", ...restProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <ButtonGroup size="sm" isAttached variant="solid" onClick={onOpen}>
        <Button mr="-px">{value ? dayjs(value).format("YYYY-MM-DD") : placeholder}</Button>
        <IconButton
          aria-label="clear date"
          icon={<SmallCloseIcon />}
          onClick={(e) => {
            e.stopPropagation();
            onChange();
            onClose();
          }}
        />
        <IconButton icon={<CalendarIcon />} />
      </ButtonGroup>

      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          onTouchStart={(e) => {
            e.stopPropagation();
          }}
        >
          <DrawerBody>
            <ReactMobileDatepicker
              {...restProps}
              value={value || undefined}
              // isOpen={true}
              isPopup={false}
              // onChange={setDate}
              theme="ios"
              showCaption={false}
              onSelect={(v) => {
                onChange && onChange(v);
                onClose();
              }}
              onCancel={onClose}
              showHeader={false}
              // onCancel={this.handleToggle(false)}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DatePicker;
