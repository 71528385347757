import React from "react";
import { Box, Button, Flex, Heading, Image, Input, Stack, Text, VStack } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";

const Page = () => {
  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Heading as="h3" size="3xl" mb={3}>
            A Sunyear
          </Heading>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <Heading as="h2" size="xl" color="#f4b12d">
              闪兑CA
            </Heading>
          </Flex>
        </Box>

        <Box bg="gray.700" p="1.2rem 2rem" pos={"relative"} zIndex={1}>
          <VStack m="xl" bg="gray.800" spacing="4" p="1.5rem 1rem" borderRadius="xl">
            <Input placeholder="请输入您希望竞购的CA数量" borderColor="white" />
            <Flex justify="space-between" align="center" w="100%" p="0 0.5rem" fontSize="0.85rem">
              <Text>您的当前账户CA余额:</Text>
              <Text>0 CA</Text>
            </Flex>
            <Flex justify="space-between" align="center" w="100%" p="0 0.5rem" fontSize="0.85rem">
              <Text>您需要消耗CA:</Text>
              <Text>0 CA</Text>
            </Flex>

            <Button w="100%" colorScheme="blue" mt="1rem">
              闪兑
            </Button>
          </VStack>

          <Stack mt="6" fontSize="0.9rem" opacity={0.8} textAlign="justify">
            <Text>提示：</Text>
            <Text>1.闪兑CA将扣除您账户中等额的CA</Text>
            <Text>2.闪兑完成后您将获得等额的USDT</Text>
          </Stack>

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
