import { ChevronLeftIcon } from "@chakra-ui/icons";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const NavBackIcon = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isTopPath = ["/", "/home", "/otc", "/team", "/mine"].includes(pathname);
  if (isTopPath) return null;
  return <ChevronLeftIcon fontSize="3xl" onClick={() => navigate(-1)} />;
};
