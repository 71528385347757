import React from "react";
import { Box, Button, Flex, Heading, Image, Input, Text, VStack } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";

const Page = () => {
  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <Heading as="h2" size="xl" color="#f4b12d">
              CA2转出
            </Heading>
          </Flex>
        </Box>

        <Box bg="gray.700" p="1.2rem 2rem" pos={"relative"} zIndex={1}>
          <Box mb={6} lineHeight={2}>
            <Text>当前持有CA: $ 0</Text>
            <Text>当前持有CA2: $ 0</Text>
            <Text>兑换比例: 1:1</Text>
          </Box>
          <VStack m="xl" bg="gray.800" spacing="4" p="1.5rem 1rem" borderRadius="xl">
            <Input placeholder="请输入您希望兑换的数量" borderColor="white" />
            <Flex justify="space-between" align="center" w="100%" p="0 0.5rem" fontSize="0.85rem">
              <Text>注意:</Text>
              <Text>兑换为单向操作不可挽回</Text>
            </Flex>

            <Button w="100%" colorScheme="blue" mt="1rem">
              确定兑换
            </Button>
          </VStack>

          {/* <Stack mt="6" fontSize="0.9rem" opacity={0.8} textAlign="justify">
            <Text>提示：</Text>
            <Text>1.竞购CA将冻结您账户中等值的USDT</Text>
            <Text>2.CA成交将获得竞购额100%的CA，自动转化为CA2，方便用户使用</Text>
            <Text>3.竞购订单按时间先后顺序排列在OTC大厅中，按顺序成交</Text>
          </Stack> */}

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
