import { useEffect, useState } from "react";
import Big from "big.js";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { web3Atom } from "@/provider/web3";
import { goldAbi, wantSaleAbi, wantAbi } from "_cfg/contract";
import { COIN_UNIT } from "_cfg/numeric";

/**
 * NFT 提现 交易
 * @param {String} walletAddress - 钱包地址
 * @returns {{
 *  mint: ({number: number, address: string}) => Promise,
 *  assets: { gold: number, ethBalance: number },
 *  isMinting:boolean,
 *  mintStatusDisplay: string
 * }}
 */
const useDMSaleTrade = (walletAddress) => {
  const toast = useToast();
  const { t } = useTranslation();
  const web3Origin = useAtomValue(web3Atom);
  const [goldCNT, setGoldCNT] = useState(null);
  const [wantSaleCNT, setWantSaleCNT] = useState(null);
  const [wantCNT, setWantCNT] = useState(null);
  const [goldMoney, setGoldMoney] = useState(0); // 代币
  const [rate, setRate] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);

  const [mintStatusDisplay, setMintStatusDisplay] = useState("");
  const [isMinting, setIsMinting] = useState(false);

  const runMyToast = ({ title, ...restOptions }) => {
    toast({ status: "info", position: "top-right", title, ...restOptions });
    setMintStatusDisplay(title);
  };
  const toastError = (title, rest) => runMyToast({ title, status: "error", ...rest });
  const toastInfo = (title, rest) => runMyToast({ title, status: "info", ...rest });

  useEffect(() => {
    if (web3Origin) initContract();
  }, [web3Origin]);

  /**
   * 初始化合约
   */
  const initContract = () => {
    const goldCNT = new web3Origin.eth.Contract(goldAbi.abi, goldAbi.address); // 代币合约
    setGoldCNT(goldCNT);
    const wantSaleCNT = new web3Origin.eth.Contract(wantSaleAbi.abi, wantSaleAbi.address); // WANT交易合约
    setWantSaleCNT(wantSaleCNT);
    const wantCNT = new web3Origin.eth.Contract(wantAbi.abi, wantAbi.address); // DM NFT合约
    setWantCNT(wantCNT);
  };

  useEffect(() => {
    if (goldCNT && wantCNT) getMoney();
  }, [goldCNT, wantCNT]);

  useEffect(() => {
    if (wantSaleCNT) getPrice();
  }, [wantSaleCNT]);

  // 刷新NFT、代币余额
  const getMoney = () => {
    if (!walletAddress) return;
    try {
      wantCNT?.methods
        .balanceOf(walletAddress)
        .call()
        .then((data) => {
          console.log("DN balanceOf ", walletAddress, " is: ", data);
          setGoldMoney(data);
        })
        .catch((err) => console.warn("Error Getting B", err));
      web3Origin.eth.getBalance(walletAddress).then((data) => {
        setEthBalance(data);
        console.log("ETH BALANCE", data);
      });
    } catch (error) {
      console.error(error);
    }
  };
  // 价格
  const getPrice = () => {
    try {
      wantSaleCNT?.methods
        .getSellDMPrice()
        .call()
        .then((data) => {
          console.log("Sale Price is: ", data);
          setRate(data);
        })
        .catch((err) => console.warn("Error Getting B", err));
    } catch (error) {
      console.error(error);
    }
  };

  // /**
  //  * mint前检查校验
  //  */
  // const preCheck = async (data) => {
  //   return Number(data.number) > COIN_UNIT;
  // };

  /**
   * 合约交易
   * @param {Object} params - 交易参数
   * @param {string|number} params.walletAddress
   * @param {number} params.number - 购买数量
   * @returns {Promise<Object>}
   */
  const mint = async ({ walletAddress: wAddress, number }) => {
    if (isMinting) return;
    toast.closeAll();
    if (!wAddress) {
      toastError(t("runes.err_connect_wallet"));
      return;
    }

    if (!number) {
      toastError(t("runes.err_input_num"));
      return;
    }

    setIsMinting(number);

    try {
      // GAS估算
      console.log("estimateGas...");
      toastInfo(t("runes.info_gas_est"));
      const gasPrice = (await web3Origin.eth.getGasPrice()) || undefined;

      const readySpend = new Big(number).mul(COIN_UNIT);

      // 判断限额是否够用
      const allowance = await wantCNT.methods.allowance(walletAddress, wantSaleAbi.address).call();
      console.log("allowance", allowance);
      console.log("gas price", gasPrice);

      toastInfo(t("trade.info_cfm_limit"));
      // 判断限额是否够用
      if (readySpend.gt(allowance)) {
        await wantCNT.methods
          .approve(wantSaleAbi.address, readySpend.toFixed())
          .send({ from: walletAddress, gasPrice })
          .then((data) => {
            // 钱包授权是否失败
            console.log("aft approve seed success", data);
          })
          .catch((err) => {
            console.log("user deny approve seed success", err);
            toastError(err.message);
            throw err;
          });
      }

      console.log("Params", readySpend.toNumber(), gasPrice);

      const gasLimit = await wantSaleCNT.methods.SellDM(readySpend.toString()).estimateGas({ from: walletAddress });
      console.log("gas info for buy:(limit, gasPrice)", gasLimit, gasPrice, "GWei");
      toastInfo(t("runes.info_ask_confirm"));

      return await wantSaleCNT.methods
        .SellDM(readySpend.toString())
        .send({ from: wAddress, gasPrice, gas: gasLimit })
        .on("transactionHash", function (txhash) {
          // 发起成功， 等待支付结果
          console.log("transactionHash, hash", txhash);
          toast({ title: t("runes.suc_waiting") });
        })
        .on("error", function (error, redeipt) {
          console.log("trading send error", error, redeipt);
          toastError(t("runes.err_start_fail"));
          setIsMinting(false);
        })
        .then(function (txData) {
          // 交易结果
          console.log("txData", txData);
          toast({ status: "success", title: t("runes.suc_trade") });
          getMoney();
          setIsMinting(false);
        });
    } catch (error) {
      console.log("mint error", error);
      let msg = error.message;
      const [err, data] = error.message.split("Internal JSON-RPC error.\n");
      msg = err.substring(0, 0) + "...";
      try {
        if (data && data[0] === "{") {
          const errInfo = JSON.parse(data);
          msg = errInfo?.message || "";
          const [raw, detail] = msg.split("Error:");
          msg = detail || raw;
        }
      } catch (error) {
        console.log("message cannot parse");
        msg = "Internal JSON-RPC error";
      }
      toastError(t("runes.err_mint_fail") + msg);
      setIsMinting(false);
      throw error;
    }
  };

  return {
    // 持有资产信息
    assets: { gold: goldMoney, ethBalance },
    rate,
    mint,
    isMinting,
    mintStatusDisplay,
  };
};

export default useDMSaleTrade;
