import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useColorMode, useColorModeValue, Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import DMMC from "_icon/DMMC";
import WalletCapsule from "../WalletCapsule";
import TabBar from "../TabBar";
import Locale from "./Locale";
import SVG_TAB_1 from "./img/tab1.svg";
import SVG_TAB_1_DARK from "./img/tab1_dark.svg";
import SVG_TAB_1_ACT from "./img/tab1_active.svg";
import SVG_TAB_2 from "./img/tab2.svg";
import SVG_TAB_2_DARK from "./img/tab2_dark.svg";
import SVG_TAB_2_ACT from "./img/tab2_active.svg";
import SVG_TAB_3 from "./img/tab3.svg";
import SVG_TAB_3_DARK from "./img/tab3_dark.svg";
import SVG_TAB_3_ACT from "./img/tab3_active.svg";
import SVG_TAB_4 from "./img/tab4.svg";
import SVG_TAB_4_DARK from "./img/tab4_dark.svg";
import SVG_TAB_4_ACT from "./img/tab4_active.svg";
import stl from "./index.module.scss";

function Navbar() {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const isDark = colorMode === "dark";
  const navigate = useNavigate();

  const tabConfig = [
    {
      value: "/home",
      match: ["/home", "/"],
      label: t("nav.1"),
      icon: <Image src={isDark ? SVG_TAB_1_DARK : SVG_TAB_1} />,
      iconActive: <Image src={SVG_TAB_1_ACT} />,
    },
    {
      value: "/news",
      label: t("nav.2"),
      icon: <Image src={isDark ? SVG_TAB_2_DARK : SVG_TAB_2} />,
      iconActive: <Image src={SVG_TAB_2_ACT} />,
    },
    // {
    //   value: "/otc",
    //   label: "OTC",
    //   icon: <Image src={isDark ? SVG_TAB_2_DARK : SVG_TAB_2} />,
    //   iconActive: <Image src={SVG_TAB_2_ACT} />,
    // },
    {
      value: "/team",
      label: t("nav.3"),
      icon: <Image src={isDark ? SVG_TAB_3_DARK : SVG_TAB_3} />,
      iconActive: <Image src={SVG_TAB_3_ACT} />,
    },
    {
      value: "/mine",
      label: t("nav.4"),
      icon: <Image src={isDark ? SVG_TAB_4_DARK : SVG_TAB_4} />,
      iconActive: <Image src={SVG_TAB_4_ACT} />,
    },
  ];

  const handleTabChange = (item, index) => {
    navigate(item.value);
  };

  return (
    <Fragment>
      <Flex className={stl.navbar} align="center" justify="space-between" marginTop={4} paddingInline="3">
        <Flex align="center" color="" flex={1}>
          <DMMC fontSize={24} />
          {/* <Image src={IMG_LOGO} width="10" /> */}
          <Text fontSize="lg" fontWeight="bold" ml={1}>
            DM
          </Text>
        </Flex>
        <HStack align="center">
          {/* <Box marginLeft="3" cursor="pointer">
            <ThemeBtn />
          </Box> */}
          <Box ml="3">
            <WalletCapsule />
          </Box>
          <Box cursor="pointer" ml={2}>
            <Locale />
          </Box>
        </HStack>
      </Flex>
      <TabBar bg={useColorModeValue("white", "gray.700")} className={stl.tabBar} tabs={tabConfig} onChange={handleTabChange} />
    </Fragment>
  );
}

export default Navbar;
