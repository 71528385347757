import React, { useState } from "react";
import { useAtomValue } from "jotai";
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { NFT_PRICE } from "_cfg/nft.config";
import useNFTTrade from "@/hoc/useNFTTrade";
import { walletAccountAtom } from "@/provider/web3";
import { useTranslation } from "react-i18next";

const NFTTrade = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const walletAddress = useAtomValue(walletAccountAtom);
  const { isMinting, mint, mintStatusDisplay } = useNFTTrade(walletAddress);
  const [NFTId, setNFTId] = useState(0);
  const [buyCount, setBuyCount] = useState(1);
  const [marketNodeId, setMarketNodeId] = useState("");

  // 发起mint
  const handleMint = async () => {
    if (!buyCount) {
      toast({ status: "error", title: "NUM must >= 1" });
      return false;
    }
    if (marketNodeId.trim() === "") {
      toast({ status: "error", title: "marketNodeId cannot be empty" });
      return false;
    }
    mint({ id: NFTId, count: buyCount, marketNodeId: Number(marketNodeId) });
  };

  const handleInputChange = (v) => {
    const { value } = v.target;
    if (value === "" || /\d+/.test(value)) {
      !(Number(value) < 0) && setMarketNodeId(value);
    }
  };

  return (
    <Box>
      <Box padding={6}>
        <HStack justifyContent="center">
          {NFT_PRICE.map((num, index) => (
            <Button
              key={num}
              colorScheme={NFTId === index ? "blue" : "gray"}
              paddingInline={6}
              size="sm"
              borderRadius={2}
              onClick={() => {
                setNFTId(index);
              }}
            >
              {num}U
            </Button>
          ))}
        </HStack>
        <Flex mt={6} w="100%" justify="space-between">
          <Text w={110} colorScheme="blue" fontWeight="bold" mr={2} lineHeight={1} alignSelf="center">
            NUM:
          </Text>
          <NumberInput flex={1} step={1} min={1} value={buyCount} onChange={setBuyCount}>
            <NumberInputField placeholder={t("trade.pld_num")} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
        <Flex mt={6}>
          <Text w={110} colorScheme="blue" fontWeight="bold" alignSelf="center" mr={2}>
            marketNodeId:
          </Text>
          <Input flex={1} value={marketNodeId} onChange={handleInputChange} />
        </Flex>

        {walletAddress ? (
          <Button
            isLoading={isMinting}
            isDisabled={isMinting}
            onClick={() => handleMint(1)}
            mt={8}
            borderRadius="full"
            letterSpacing={1}
            width="100%"
            size="lg"
            colorScheme="blue"
            bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
            loadingText={<Text fontSize="sm">{mintStatusDisplay.substring(0, 12)}...</Text>}
          >
            {t("trade.mint")}
          </Button>
        ) : (
          <Button
            mt={8}
            flex={1}
            colorScheme="blue"
            bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
            width="100%"
            size="lg"
            borderRadius="2xl"
            onClick={navigate("/mineLogin")}
          >
            {t("trade.login")}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default NFTTrade;
