import React from "react";
import { Box, Flex, Heading, Image, VStack } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import NFTTrade from "./NFTTrade";

const Page = () => {
  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center">
            <NavBackIcon />
            <Heading as="h2" size="xl">
              Buy DM
            </Heading>
          </Flex>
          <Heading mb={8} as="h2" size="xl" className={cls.colorful}>
            Truth and Faith
          </Heading>
        </Box>

        <Box className={cls.cnt} bg="gray.800" p="1rem 2rem">
          <NFTTrade />

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
