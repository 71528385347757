import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "@/pages/HomePage";
import OTCPage from "@/pages/OTCPage";
import TeamPage from "@/pages/TeamPage";
import MinePage from "@/pages/MinePage";
import MineLoginPage from "@/pages/MineLoginPage";
import MineRegisterPage from "@/pages/MineRegisterPage";
import MineChangePage from "@/pages/MineChangePage";
import MineResetPage from "@/pages/MineResetPage";
import MineWalletPage from "@/pages/MineWalletPage";
import MineInvestPage from "@/pages/MineInvestPage";
import MineCoinDetailPage from "@/pages/MineCoinDetailPage";
import MineTransferPage from "@/pages/MineTransferPage";
import MineTransferOutPage from "@/pages/MineTransferOutPage";
import MineTransferDMPage from "@/pages/MineTransferDMPage";
import MineTransferDMOutPage from "@/pages/MineTransferDMOutPage";
import MineASYSellPage from "@/pages/MineASYSellPage";
import MineOTCRecordPage from "@/pages/MineOTCRecordPage";
import MineImportPage from "@/pages/MineImportPage";
import MineNetworkPage from "@/pages/MineNetworkPage";
import MineLinkPage from "@/pages/MineLinkPage";
import MineAddLinkPage from "@/pages/MineAddLinkPage";
import MineMatchRecordPage from "@/pages/MineMatchRecordPage";
import MineSubPage from "@/pages/MineSubPage";
import MineAddSubPage from "@/pages/MineAddSubPage";
import TeamUserListPage from "@/pages/TeamUserListPage";
import OTCBuyPage from "@/pages/OTCBuyPage";
import NewsPage from "@/pages/NewsPage";
import NewsDetailPage from "@/pages/NewsDetailPage";
import OTCFlashPage from "@/pages/OTCFlashPage";
import HomeHatchPage from "@/pages/HomeHatchPage";
import MineMintPage from "@/pages/MineMintPage";
import MineRunesPage from "@/pages/MineRunesPage";
import HomeEnergyPage from "@/pages/HomeEnergyPage";
import HomeMatchQueuePage from "@/pages/HomeMatchQueuePage";

const PageRoutes = () => (
  <Routes>
    <Route exact path="/" element={<HomePage />} />
    <Route exact path="/home" element={<HomePage />} />
    <Route exact path="/news" element={<NewsPage />} />
    <Route exact path="/otc" element={<OTCPage />} />
    <Route exact path="/team" element={<TeamPage />} />
    <Route exact path="/mine" element={<MinePage />} />

    <Route exact path="/newsDetail" element={<NewsDetailPage />} />

    <Route exact path="/mineLogin" element={<MineLoginPage />} />
    <Route exact path="/mineRegister" element={<MineRegisterPage />} />
    <Route exact path="/mineChange" element={<MineChangePage />} />
    <Route exact path="/mineReset" element={<MineResetPage />} />

    <Route exact path="/mineMint" element={<MineMintPage />} />

    <Route exact path="/mineRunes" element={<MineRunesPage />} />

    <Route exact path="/mineWallet" element={<MineWalletPage />} />
    <Route exact path="/mineInvest" element={<MineInvestPage />} />
    <Route exact path="/mineCoinDetail" element={<MineCoinDetailPage />} />
    <Route exact path="/mineTransfer" element={<MineTransferPage />} />
    <Route exact path="/mineTransferOut" element={<MineTransferOutPage />} />
    <Route exact path="/mineTransferDM" element={<MineTransferDMPage />} />
    <Route exact path="/mineTransferDMOut" element={<MineTransferDMOutPage />} />

    <Route exact path="/mineASYSell" element={<MineASYSellPage />} />
    <Route exact path="/mineOTCRecord" element={<MineOTCRecordPage />} />
    <Route exact path="/mineImport" element={<MineImportPage />} />
    <Route exact path="/mineNetwork" element={<MineNetworkPage />} />

    <Route exact path="/mineLink" element={<MineLinkPage />} />
    <Route exact path="/mineAddLink" element={<MineAddLinkPage />} />
    <Route exact path="/mineMatchRecord" element={<MineMatchRecordPage />} />
    <Route exact path="/mineSub" element={<MineSubPage />} />
    <Route exact path="/mineAddSub" element={<MineAddSubPage />} />
    <Route exact path="/teamUserList" element={<TeamUserListPage />} />

    <Route exact path="/otcBuy" element={<OTCBuyPage />} />
    <Route exact path="/otcFlash" element={<OTCFlashPage />} />

    <Route exact path="/homeHatch" element={<HomeHatchPage />} />
    <Route exact path="/homeEnergy" element={<HomeEnergyPage />} />
    <Route exact path="/homeMatchQueue" element={<HomeMatchQueuePage />} />
  </Routes>
);

export default PageRoutes;
