/* eslint-disable max-len */
import React from "react";
import { Icon } from "@chakra-ui/react";

const WalletIcon = (props) => (
  <Icon viewBox="0 0 1024 1024" {...props}>
    <path
      fill="currentColor"
      d="M703.99 543.999c-35.344 0-63.996 28.652-63.996 63.996s28.653 63.997 63.997 63.997h127.994c35.345 0 63.996-28.653 63.996-63.997S867.33 544 831.985 544H703.99z m0-63.998h127.995c70.689 0 127.994 57.305 127.994 127.994S902.674 735.99 831.985 735.99H703.99c-70.69 0-127.994-57.305-127.994-127.994s57.305-127.994 127.994-127.994z m-364.56-223.99h396.56v-100.21c0-3.775-0.669-7.52-1.973-11.062-6.11-16.582-24.506-25.073-41.088-18.962l-353.5 130.235z m-185.664 0.201L670.804 65.725c49.748-18.329 104.935 7.143 123.263 56.89a95.99 95.99 0 0 1 5.919 33.186v100.212h31.999c53.016 0 95.995 42.979 95.995 95.995v63.997c0 17.672-14.326 31.998-31.999 31.998s-31.998-14.326-31.998-31.998v-63.997c0-17.672-14.327-31.999-32-31.999H160.017c-17.672 0-31.998 14.327-31.998 32v511.975c0 17.672 14.326 31.998 31.998 31.998h671.968c17.672 0 31.999-14.326 31.999-31.998v-63.997c0-17.672 14.326-31.999 31.998-31.999s32 14.327 32 31.999v63.997c0 53.016-42.98 95.995-95.996 95.995h-671.97c-53.016 0-95.995-42.979-95.995-95.995V352.007c0-50.917 39.642-92.574 89.745-95.795z"
    ></path>
  </Icon>
);

export default WalletIcon;
