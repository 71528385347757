import React, { useEffect, useRef } from "react";
import loadJs from "loadjs";
import { useSearchParams } from "react-router-dom";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import "./Container.scss";

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
  // blue: {
  //   100: "#0c9ff6",
  //   200: "#0c9ff6",
  //   300: "#0c9ff6",
  //   400: "#0c9ff6",
  //   500: "#0c9ff6",
  //   600: "#0c9ff6",
  //   700: "#0c9ff6",
  //   800: "#0c9ff6",
  //   900: "#0c9ff6",
  // },
};

const fonts = {
  // heading: `"Source Han Sans CN", "思源黑体"`,
  // body: `"Source Han Sans CN", "思源黑体"`,
};

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const borderRadius = {
  radii: {
    none: "0",
    sm: "0.125rem",
    base: "0.25rem",
    md: "0.375rem",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
  },
};

const styles = {
  global: (props) => ({
    body: {
      color: mode("gray.900", "#fcfcfd")(props),
      bg: mode("gray.50", "#000")(props),
      // lineHeight: 'base',
    },
  }),
};

const theme = extendTheme({ ...borderRadius, styles, colors, config, fonts });

// 3. Pass the `theme` prop to the `ChakraProvider`
function Container({ children }) {
  let clicked = 0;

  const erudaClickTimer = useRef(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    console.log(searchParams.get("__doctor"));
    searchParams.get("__doctor") === "1" && window.eruda?.init?.();
  }, []);

  // inject doctor
  const handleClick = async (e) => {
    clearTimeout(erudaClickTimer);
    if (window.eruda) return;
    const { clientX, clientY } = e;
    if (clientX < 80 && clientY < 80) {
      clicked += 1;
      if (clicked > 9) {
        clicked = 0;
        await loadEruda();
        return;
      }
      // 超时清理计数器
      erudaClickTimer.current = setTimeout(() => {
        clicked = 0;
      }, 2000);
    }
  };

  const loadEruda = async () => {
    await loadJs(process.env.PUBLIC_URL + "/lib/eruda.js", { returnPromise: true });
    window.eruda.init();
  };

  return (
    <ChakraProvider theme={theme}>
      <div className="basic-layout-container" onClick={window.eruda ? undefined : handleClick}>
        {children}
      </div>
    </ChakraProvider>
  );
}

export default Container;
