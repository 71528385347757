import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  useToast,
} from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import PasswordInput from "_c/PasswordInput";
import { AtSignIcon, LockIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import withWalletConnect from "@/hoc/withWalletConnect";
import { useAtomValue } from "jotai";
import { walletAccountAtom, web3Atom } from "@/provider/web3";
import { walletAuthBind, walletAuthLogin } from "_u/web3Login";

const Page = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [needBind, setNeedBind] = useState(false);
  const toast = useToast();

  const canSubmit = username && pwd;

  const web3 = useAtomValue(web3Atom);
  const walletAccount = useAtomValue(walletAccountAtom);
  const isConnected = web3 && walletAccount && typeof walletAccount === "string";

  const ConnectBtn = withWalletConnect({ injectWallet: false, onError: (err) => toast({ title: err.message }) })(Button);

  useEffect(() => {
    // if (web3) handleWalletAuth();
    console.log("-------web3", web3);
  }, [web3]);

  const handleWalletAuth = async () => {
    if (!web3) return;
    try {
      try {
        const { needBind } = await walletAuthLogin(web3, walletAccount);
        console.log("Login success");
        if (needBind) {
          setNeedBind(true);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.warn(error);
        toast({ title: t("login.msg_sys_error") });
      }
    } catch (error) {
      console.warn(error);
      toast({ title: t("login.msg_auth_fail") });
    }
  };

  const handAuth = async () => {
    try {
      const resp = await walletAuthBind(web3, walletAccount, { username, password: pwd });
      console.log(resp);
      navigate("/", { replace: true });
    } catch (error) {
      console.warn(error);
      toast({ title: t("login.msg_wallet_auth_fail") });
    }
  };

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Heading as="h3" size="md" mb={3}>
            {t("login.title_1")}
          </Heading>
          <Heading as="h2" size="2xl" mb={8} className={cls.colorful}>
            {t("login.title_2")}
          </Heading>
        </Box>

        <Box className={cls.cnt} p={10} py={0}>
          {isConnected && needBind ? (
            <Box>
              <Box>
                <FormControl mb={6}>
                  <FormLabel>{t("login.username")}</FormLabel>
                  <InputGroup>
                    <InputLeftElement>
                      <AtSignIcon />
                    </InputLeftElement>
                    <Input value={username} onChange={(e) => setUsername(e.target.value)} placeholder={t("login.username_pld")} />
                  </InputGroup>
                </FormControl>
                <FormControl mb={6}>
                  <FormLabel>{t("login.auth_token")}</FormLabel>
                  <PasswordInput
                    left={
                      <InputLeftElement>
                        <LockIcon />
                      </InputLeftElement>
                    }
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    placeholder={t("login.auth_token_pld")}
                  />
                </FormControl>
                {/* <FormControl mb={6}>
                  <Checkbox colorScheme="blue" value={rememberMe} onChange={(v) => setRememberMe(v)}>
                    记住我
                  </Checkbox>
                </FormControl> */}
              </Box>

              <Button
                w="100%"
                colorScheme="blue"
                bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
                isDisabled={!canSubmit}
                onClick={handAuth}
              >
                {t("login.auth_btn")}
              </Button>

              {/* <Button mt={8} w="100%" colorScheme="orange" variant="link" onClick={() => navigate("/mineReset")}>
                忘记密码?
              </Button> */}
            </Box>
          ) : (
            <Box>
              <ConnectBtn
                w="100%"
                size="lg"
                borderRadius="xl"
                colorScheme="blue"
                bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
                onClick={handleWalletAuth}
              >
                {web3 ? t("login.wallet_auth_btn") : t("login.connect_btn")}
              </ConnectBtn>
            </Box>
          )}

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
