import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Box, Button, Heading, Image, Link, SimpleGrid, VStack } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/otc-bg.jpg";
import cls from "./index.module.scss";

const Page = () => {
  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Heading as="h3" size="md" mb={3}>
            竞购总额
          </Heading>
          <Heading as="h2" size="2xl" mb={8}>
            $ {Number(1002222).toLocaleString()}
          </Heading>
          <SimpleGrid columns={3} spacing={3}>
            <Link as={ReactRouterLink} to="/otcBuy">
              <Button variant="solid" w="100%" colorScheme="blue">
                竞购
              </Button>
            </Link>
            <Link as={ReactRouterLink} to="/otcFlash">
              <Button variant="solid" w="100%" colorScheme="blue">
                闪兑
              </Button>
            </Link>
            <Link as={ReactRouterLink} to="/mineOTCRecord?type=OTC">
              <Button variant="outline" w="100%" colorScheme="white">
                交易记录
              </Button>
            </Link>
          </SimpleGrid>
        </Box>

        <Box bg="gray.600" p="1rem 2rem">
          Content
          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
