import { useEffect, useRef, useState } from "react";
import request from "_u/request";

const useUserInfo = () => {
  const pending = useRef(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    if (pending.current) return;
    pending.current = true;
    try {
      const dt = await request("gm/api/user/selfinfo");
      console.log(dt);
      setData(dt);
    } catch (error) {
      console.warn(error);
    }
    pending.current = false;
  }

  return { userInfo: data, refresh: fetchData };
};

export default useUserInfo;
