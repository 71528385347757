import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { walletAccountAtom, web3Atom } from "@/provider/web3";
import { connectWallet, clearCachedProvider } from "_u/web3Util";
import { BSC_NETWORK } from "_cfg/network";
import { walletAuthLogout } from "_u/web3Login";

/**
 * 赋能点击链接钱包功能
 */
export default ({ injectWallet = true, onError } = {}) =>
  (WrappedComponent) => {
    function WithWallet(props) {
      const { onClick, ...restProps } = props;

      let [web3, setWeb3] = useAtom(web3Atom);
      let [walletAccount, setWalletAccount] = useAtom(walletAccountAtom);
      const [unsupported, setUnsupported] = useState(false);

      useEffect(() => {
        if (walletAccount && !web3) {
          handleClick();
        }
      }, [walletAccount, web3]);

      const checkNetworkFittable = (chainId) => {
        console.warn("chainId", chainId);
        setUnsupported(BSC_NETWORK.chainId !== chainId);
      };

      const handleClick = async () => {
        try {
          if (!web3) {
            web3 = await connectWallet({
              onConnect: ({ chainId }) => {
                checkNetworkFittable(chainId);
              },
              onChainChanged: (res) => {
                // 是否当前支持的网络
                checkNetworkFittable(res);
                console.log("Network Changed");
                // MetaMask recommends reloading the page unless you have good reason not to
              },
              onAccountsChanged: (accounts) => {
                console.log("accountsChanged", accounts);
                if (accounts.length === 0) {
                  // MetaMask is locked or the user doesn't have any connected accounts
                  console.log("Please connect to MetaMask.");
                  setWalletAccount("");
                } else {
                  setWalletAccount(accounts[0]);
                }
                walletAuthLogout();
              },

              onDisconnect: (error) => {
                console.log("MetaMask disconnected.", error);
                clearCachedProvider();
                setWalletAccount("");
              },
            });
            setWeb3(web3);
          }

          const addresses = await web3.eth.requestAccounts();
          walletAccount = addresses?.[0] || null;

          setWalletAccount(walletAccount);
          typeof onClick === "function" && onClick();
        } catch (error) {
          onError && onError(error);
        }
      };

      const web3Info = injectWallet ? { web3, walletAccount, unsupported } : {};
      return <WrappedComponent {...restProps} onClick={handleClick} {...web3Info} />;
    }

    return WithWallet;
  };
