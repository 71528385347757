import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Center, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import cls from "./index.module.scss";

export const CustomTable = ({ columns, pending, dataList: sourceData, children, ...rest }) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState(sourceData);

  useEffect(() => {
    setDataList(sourceData);
  }, [sourceData]);

  const pendingContent = pending ? (
    <Tr>
      <Td colSpan={columns?.length}>
        <Center>
          <Spinner></Spinner>
        </Center>
      </Td>
    </Tr>
  ) : null;

  let tableCnt = pending ? null : (
    <Tr>
      <Td colSpan={columns?.length}>
        <Center>
          <Text p="6" opacity={0.5}>
            {t("components.list.empty")}
          </Text>
        </Center>
      </Td>
    </Tr>
  );

  if (dataList?.length > 0) {
    tableCnt = dataList.map((item, index) => {
      // 值更新器
      const updater = (newItem, allList) => {
        if (allList !== undefined) {
          setDataList([...allList]);
        } else {
          dataList.splice(index, 1, { ...newItem });
          setDataList([...dataList]);
        }
      };
      return (
        <Tr key={index}>
          {columns.map((column, idx) => (
            <Td key={idx} textAlign={column.align}>
              {column.render ? column.render(item, index, updater) : item.useIndex ? index : item[column.key]}
            </Td>
          ))}
        </Tr>
      );
    });
  }

  return (
    <Box w="full" borderRadius="md">
      <TableContainer className={cls.table} {...rest}>
        <Table size="sm">
          <Thead bg="gray.800" borderRadius="xl" overflow="hidden">
            <Tr>
              {columns.map((item, index) => (
                <Th key={index} isNumeric={item.isNumeric} textAlign={item.align}>
                  {item.title}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {tableCnt}
            {pendingContent}
          </Tbody>
        </Table>
      </TableContainer>
      {children}
    </Box>
  );
};
