import React from "react";
import { Box, Flex, Button, Heading, Image, Link, SimpleGrid, VStack } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/trade-bg.jpg";
import USDT from "_icon/USDT";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import cls from "./index.module.scss";
import CustomInfiniteDataTable from "_c/CustomDataTable/Infinite";

const Page = () => {
  const columns = [
    { title: "时间", key: "time", align: "right" },
    { title: "额度", key: "amount", isNumeric: true, align: "left" },
    { title: "途径", align: "right", key: "source" },
  ];
  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <USDT fontSize="42px" />
            <Heading as="h2" size="2xl" ml={2}>
              USDT
            </Heading>
          </Flex>
          <Heading as="h6" size="sm" mb={3} opacity={0.6}>
            当前余额
          </Heading>
          <Heading as="h2" size="2xl" mb={8}>
            $ {Number(1002222).toLocaleString()}
          </Heading>
          <SimpleGrid columns={2} spacing={3}>
            <Link href="/mineInvest?type=deposit">
              <Button size="lg" variant="solid" w="100%" colorScheme="blue">
                充值
              </Button>
            </Link>
            <Link href="/mineInvest?type=withdraw">
              <Button size="lg" variant="outline" w="100%" colorScheme="white">
                提币
              </Button>
            </Link>
          </SimpleGrid>
        </Box>

        <Box className={cls.cnt} bg="gray.700" p={6}>
          <CustomInfiniteDataTable api={`/gm/api/tradeorder/hallself"}`} columns={columns} />
          <VStack h="60px" />
        </Box>
        <VStack h="60px" />
      </Box>
    </div>
  );
};

export default Page;
