import request, { Method } from "@/utils/request.js";

/**
 * 获取随机签名串
 */
export function queryBindNonce(address, inviteCode, sceneCode = "BindCode") {
  return request("/gm/get_vcode", {
    method: Method.POST,
    body: { walletAddress: address, invitorCode: inviteCode || undefined, sceneCode },
  }).then((resp) => {
    if (resp.loginCode) {
      return resp.loginCode;
    } else {
      throw new Error("授权失败");
    }
  });
}

/**
 * 获取随机签名串
 */
export function queryNonce(address, inviteCode, sceneCode = "LoginCode") {
  return request("/gm/get_vcode", {
    method: Method.POST,
    body: { walletAddress: address, invitorCode: inviteCode || undefined, sceneCode },
  }).then((resp) => {
    if (sceneCode === "LoginCode" && resp.userExistFlag === false) {
      // 导航到登录页面, 需要新绑定
      if (location.pathname !== "/mineLogin") {
        location.href = "/mineLogin";
      }
      return null;
    } else if (resp.loginCode) {
      return resp.loginCode;
    } else {
      throw new Error("授权失败");
    }
  });
}

/**
 * 使用钱包联合登录
 */
export function unionLogin(data) {
  return request("/gm/login_by_wallet", {
    method: Method.POST,
    body: data,
  }).then((resp) => {
    if (resp.accessToken) {
      return resp.accessToken;
    } else {
      throw new Error("授权登录失败");
    }
  });
}

export function unionPwdLogin(data) {
  return request("/gm/login_by_passwd", {
    method: Method.POST,
    body: data,
  }).then((resp) => {
    if (resp.accessToken) {
      return resp.accessToken;
    } else {
      throw new Error("授权登录失败");
    }
  });
}

/**
 * 钱包授权登录
 * @param {web3 Eth} web3Provider web3 Provider
 * @param {string} address 钱包地址
 */
export async function walletAuthLogin(web3Provider, address, { inviteCode } = {}) {
  console.log("address: ", address);
  const nonce = await queryNonce(address, inviteCode);
  if (!nonce) return { needBind: true, accessToken: "" };
  const sign = await web3Provider.eth.personal.sign(nonce, address);
  // console.log("sign: ", sign);
  const accessToken = await unionLogin({ walletAddress: address, vCode: nonce, passPhrase: sign });
  document.cookie = `accessToken=${accessToken}`;
  // console.log("accessToken: ", accessToken);
  return { accessToken: accessToken || "" };
}

/**
 * 钱包授权登录
 * @param {web3 Eth} web3Provider web3 Provider
 * @param {string} address 钱包地址
 */
export async function walletAuthBind(web3Provider, address, { inviteCode, username, password }) {
  console.log("address: ", address);
  const nonce = await queryBindNonce(address);
  const sign = await web3Provider.eth.personal.sign(nonce, address);
  // console.log("sign: ", sign);
  const accessToken = await unionPwdLogin({ walletAddress: address, vCode: nonce, username, password, passPhrase: sign });
  document.cookie = `accessToken=${accessToken}`;
  // console.log("accessToken: ", accessToken);
  return { accessToken: accessToken || "" };
}

/**
 * 注销登录
 */
export async function walletAuthLogout() {
  // document.cookie = `accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  try {
    await request("/gm/api/login_out", { method: Method.POST });
    if (location.pathname !== "/mineLogin") {
      location.href = "/mineLogin";
    }
  } catch (error) {
    console.warn(error);
  }
}
