import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, FormControl, FormLabel, Heading, Image, VStack, useToast } from "@chakra-ui/react";
import request, { Method } from "_u/request";
import Navbar from "_c/Navbar";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import PasswordInput from "_c/PasswordInput";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";

const Page = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [oldPwd, setOldPwd] = useState("");
  const [pwd, setPwd] = useState("");
  const [rptPwd, setRptPwd] = useState("");

  const canSubmit = oldPwd && pwd && rptPwd;

  const doChange = async () => {
    try {
      toast.closeAll();
      if (pwd !== rptPwd) {
        return toast({ title: t("mine_change.msg_repeat_err"), status: "error" });
      }
      const data = await request("gm/api/user/set_passwd", {
        method: Method.POST,
        body: {
          passwd: pwd,
        },
      });
      if (data) {
        toast({ title: t("mine_change.msg_cg_suc"), status: "success" });
        navigate(-1);
      } else {
        toast({ title: t("mine_change.msg_cg_fail"), status: "error" });
      }
    } catch (error) {
      toast({ title: t("mine_change.msg_cg_fail") + ": " + error.message, status: "error" });
    }
  };

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mt={8}>
            <NavBackIcon />
            <Heading as="h2" size="lg">
              {t("mine_change.title")}
            </Heading>
          </Flex>
        </Box>

        <Box className={cls.cnt} p={10} pt={2}>
          <Box>
            <FormControl mb={6}>
              <FormLabel>{t("mine_change.current")}</FormLabel>
              <PasswordInput
                value={oldPwd}
                onChange={(e) => setOldPwd(e.target.value)}
                placeholder={t("mine_change.current_pld")}
              />
            </FormControl>
            <FormControl mb={6}>
              <FormLabel>{t("mine_change.new")}</FormLabel>
              <PasswordInput
                min="8"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                placeholder={t("mine_change.new_pld")}
              />
            </FormControl>
            <FormControl mb={6}>
              <FormLabel>{t("mine_change.repeat")}</FormLabel>
              <PasswordInput
                min="8"
                value={rptPwd}
                onChange={(e) => setRptPwd(e.target.value)}
                placeholder={t("mine_change.repeat_pld")}
              />
            </FormControl>
          </Box>

          <Button
            w="100%"
            colorScheme="blue"
            bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
            isDisabled={!canSubmit}
            onClick={doChange}
          >
            {t("mine_change.submit_btn")}
          </Button>

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
