import { CheckIcon } from "@chakra-ui/icons";
import { IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import GlobalIcon from "_icon/GlobalIcon";
import React from "react";
import { useTranslation } from "react-i18next";

const Locale = () => {
  const { i18n } = useTranslation();
  const current = i18n.language;

  const toggle = (loc) => () => {
    i18n.changeLanguage(loc);
  };

  return (
    <Menu>
      <MenuButton as={IconButton} borderRadius="3xl" icon={<GlobalIcon fontSize="24" />} />
      <MenuList zIndex={100} minW={100}>
        <MenuItem onClick={toggle("zh-CN")}>
          {current === "zh-CN" && <CheckIcon />}
          <Text ml={2}>简体中文</Text>
        </MenuItem>
        <MenuItem onClick={toggle("zh-HK")}>
          {["zh-TW", "zh-HK"].includes(current) && <CheckIcon />}
          <Text ml={2}>繁体中文</Text>
        </MenuItem>
        <MenuItem onClick={toggle("en")}>
          {current === "en" && <CheckIcon />}
          <Text ml={2}>English</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default Locale;
