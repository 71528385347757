import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";

const Page = () => {
  const [saBalance, setSaBalance] = useState(0);
  const [username, setUsername] = useState("");
  const [pkg, setPkg] = useState("100");

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <Heading as="h2" size="xl">
              新增子账户
            </Heading>
          </Flex>
        </Box>

        <Box bg="gray.700" p="1.2rem 2rem" pos={"relative"} zIndex={1}>
          <VStack m="xl" bg="gray.800" spacing="4" p="1.5rem 1rem" borderRadius="xl">
            <FormControl mb={2}>
              <FormLabel>主账户SA余额</FormLabel>
              <Input value={saBalance} onChange={(e) => setSaBalance(e.target.value)} placeholder="请输入白名单" />
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>子账户配套额度</FormLabel>
              <Select value={pkg} onChange={setPkg} placeholder="请选择配套额度">
                <option value="100">$ 100</option>
                <option value="500">$ 500</option>
                <option value="1000">$ 1000</option>
                <option value="2000">$ 2000</option>
                <option value="3000">$ 3000</option>
              </Select>
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>注册白名单</FormLabel>
              <Input
                min={6}
                max={15}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="请输入您要注册的子账户白名单"
              />
              <FormHelperText>自定义白名单，6-15位数字及字母</FormHelperText>
            </FormControl>

            <Flex justify="space-between" align="center" w="100%" p="0 0.5rem" fontSize="0.85rem">
              <Text>注意：如当前存在队列，则子账户注册同样进入队列进行排序:</Text>
            </Flex>

            <Button w="100%" colorScheme="blue" mt="1rem">
              确认注册
            </Button>
          </VStack>

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
