/* eslint-disable max-len */
import React from "react";
import { Icon } from "@chakra-ui/react";

const BUSD = (props) => (
  <Icon viewBox="0 0 336.41 337.42" {...props}>
    <g fill="currentColor">
      <path d="M168.2.71l41.5,42.5L105.2,147.71l-41.5-41.5Z" />
      <path d="M231.2,63.71l41.5,42.5L105.2,273.71l-41.5-41.5Z" />
      <path d="M42.2,126.71l41.5,42.5-41.5,41.5L.7,169.21Z" />
      <path d="M294.2,126.71l41.5,42.5L168.2,336.71l-41.5-41.5Z" />
    </g>
  </Icon>
);

export default BUSD;
