/* eslint-disable camelcase */
import { CURRENT_IS_TEST } from "./env";

import {
  gmAbi as T_gmAbi,
  tradeAbi as T_tradeAbi,
  goldAbi as T_goldAbi,
  wantAbi as T_wantAbi,
  wantSaleAbi as T_wantSaleAbi,
  lpTokenAbi as T_lpTokenAbi,
  stakeAbi as T_stakeAbi,
  stakeSmallGMAbi as T_stakeSmallGMAbi,
  tvlAbi as T_tvlAbi,
  runesAbi as T_runesAbi,
} from "./contract_test";

import {
  gmAbi as M_gmAbi,
  tradeAbi as M_tradeAbi,
  goldAbi as M_goldAbi,
  wantAbi as M_wantAbi,
  wantSaleAbi as M_wantSaleAbi,
  lpTokenAbi as M_lpTokenAbi,
  stakeAbi as M_stakeAbi,
  stakeSmallGMAbi as M_stakeSmallGMAbi,
  tvlAbi as M_tvlAbi,
  runesAbi as M_runesAbi,
} from "./contract_main";

/**
 * TVL abi
 */
export const tvlAbi = CURRENT_IS_TEST ? T_tvlAbi : M_tvlAbi;
/**
 * NFT abi
 */
export const gmAbi = CURRENT_IS_TEST ? T_gmAbi : M_gmAbi;
/**
 * 交易 abi
 */
export const tradeAbi = CURRENT_IS_TEST ? T_tradeAbi : M_tradeAbi;

/**
 * 代币 abi
 */
export const goldAbi = CURRENT_IS_TEST ? T_goldAbi : M_goldAbi;

/**
 * WanT Abi
 */
export const wantAbi = CURRENT_IS_TEST ? T_wantAbi : M_wantAbi;
/**
 * WantTSale Abi
 */
export const wantSaleAbi = CURRENT_IS_TEST ? T_wantSaleAbi : M_wantSaleAbi;
/**
 * WanT/Busd LPToken Abi
 */
export const lpTokenAbi = CURRENT_IS_TEST ? T_lpTokenAbi : M_lpTokenAbi;
/**
 * Stake Abi
 */
export const stakeAbi = CURRENT_IS_TEST ? T_stakeAbi : M_stakeAbi;
/**
 * Stake smallgm Abi
 */
export const stakeSmallGMAbi = CURRENT_IS_TEST ? T_stakeSmallGMAbi : M_stakeSmallGMAbi;

/**
 * Runes Abi
 */
export const runesAbi = CURRENT_IS_TEST ? T_runesAbi : M_runesAbi;
