import React from "react";
import { Box, Button, useMultiStyleConfig, useTab } from "@chakra-ui/react";

import cls from "./Tabbar.module.scss";
import clsx from "clsx";

// 导航按钮
const TabBarItem = React.forwardRef(({ iconActive, ...props }, ref) => {
  // 1. Reuse the `useTab` hook
  const tabProps = useTab({ ...props, ref });
  const isSelected = `${tabProps["aria-selected"]}` === "true";

  // 2. Hook into the Tabs `size`, `variant`, props
  const styles = useMultiStyleConfig("Tabs", { ...tabProps });

  return (
    <Button
      className={clsx("tab-bar-item_icon", cls.item, { active: !!isSelected })}
      __css={styles.tab}
      borderBottom="none"
      {...tabProps}
    >
      <Box className="tab-bar-item_icon" mb={1}>
        {isSelected ? !!iconActive && iconActive : !!props.icon && props.icon}
      </Box>
      <Box fontSize="sm" className="tab-bar-item_label">
        {tabProps.children}
      </Box>
    </Button>
  );
});

TabBarItem.displayName = "TabBarItem";

export default TabBarItem;
