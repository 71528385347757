/* eslint-disable max-len */
import React from "react";
import { Icon } from "@chakra-ui/react";

const GlobalIcon = (props) => (
  <Icon viewBox="0 0 1066 1024" {...props}>
    <g fill="currentColor">
      <path d="M535.04 373.333333c-65.792 0-118.570667 52.821333-118.570667 117.333334a42.666667 42.666667 0 1 1-85.333333 0c0-112.213333 91.605333-202.666667 203.904-202.666667a42.666667 42.666667 0 1 1 0 85.333333zM304.853333 820.992a384 384 0 0 0 609.962667-348.8l3.157333-3.498667c32.341333-36.224 57.770667-72.917333 71.850667-107.946666 13.824-34.389333 19.370667-74.538667-1.194667-109.909334-23.338667-40.149333-68.693333-54.485333-111.488-57.685333-34.133333-2.56-73.6 1.194667-116.053333 9.984a384 384 0 0 0-610.133333 348.8c-30.549333 33.706667-54.784 67.413333-69.845334 99.498667-17.664 37.589333-26.88 82.645333-4.053333 121.898666 20.565333 35.328 58.368 50.688 95.317333 55.978667 37.546667 5.418667 82.304 1.877333 130.048-7.808l2.432-0.512zM532.864 213.333333a298.794667 298.794667 0 0 1 290.773333 230.314667c-57.173333 55.893333-136.96 115.882667-231.68 170.154667-95.018667 54.485333-187.690667 93.184-265.216 114.346666A298.666667 298.666667 0 0 1 532.821333 213.333333z m295.68 340.778667a298.752 298.752 0 0 1-409.429333 234.154667c68.266667-24.32 141.909333-58.368 215.765333-100.693334 72.96-41.813333 138.752-87.509333 193.706667-133.461333z m-600.106667 192a214.058667 214.058667 0 0 1-44.117333-1.024c-24.704-3.541333-31.530667-11.264-33.237333-14.165333-1.92-3.328-5.589333-15.232 7.594666-43.306667 4.522667-9.642667 10.581333-20.138667 18.176-31.36a383.488 383.488 0 0 0 51.626667 89.856z m608.981334-468.053333c12.373333-0.810667 23.552-0.853333 33.536-0.085334 32.725333 2.474667 41.685333 11.946667 43.648 15.317334 1.706667 2.944 4.992 12.672-4.266667 35.669333-4.608 11.52-11.733333 24.576-21.461333 38.912a383.573333 383.573333 0 0 0-51.456-89.770667z" />
    </g>
  </Icon>
);

export default GlobalIcon;
