import { useEffect, useState } from "react";
import Big from "big.js";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { postRuneTxHash, queryRunePreCheck } from "@/api/gm";
import { web3Atom } from "@/provider/web3";
import { goldAbi, runesAbi } from "_cfg/contract";
import { COIN_UNIT } from "_cfg/numeric";
import { RUNES_PRICE } from "_cfg/nft.config";

/**
 * NFT交易
 * @param {String} walletAddress - 钱包地址
 * @returns {{
 *  mint: ({number: number, address: string}) => Promise,
 *  assets: { gold: number, ethBalance: number },
 *  isMinting:boolean,
 *  mintStatusDisplay: string
 * }}
 */
const useRuneTrade = (walletAddress) => {
  const toast = useToast();
  const { t } = useTranslation();
  const web3Origin = useAtomValue(web3Atom);
  const [goldCNT, setGoldCNT] = useState(null);
  const [runeCNT, setRuneCNT] = useState(null);
  const [goldMoney, setGoldMoney] = useState(0); // 代币
  const [ethBalance, setEthBalance] = useState(0);

  const [mintStatusDisplay, setMintStatusDisplay] = useState("");
  const [isMinting, setIsMinting] = useState(false);

  const runMyToast = ({ title, ...restOptions }) => {
    toast({ status: "info", position: "top-right", title, ...restOptions });
    setMintStatusDisplay(title);
  };
  const toastError = (title, rest) => runMyToast({ title, status: "error", ...rest });
  const toastInfo = (title, rest) => runMyToast({ title, status: "info", ...rest });

  useEffect(() => {
    if (web3Origin) initContract();
  }, [web3Origin]);

  /**
   * 初始化合约
   */
  const initContract = () => {
    const goldCNT = new web3Origin.eth.Contract(goldAbi.abi, goldAbi.address); // 代币合约
    setGoldCNT(goldCNT);
    const runeCNT = new web3Origin.eth.Contract(runesAbi.abi, runesAbi.address); // RUNE NFT合约
    setRuneCNT(runeCNT);
  };

  useEffect(() => {
    if (goldCNT && runeCNT) getMoney();
  }, [goldCNT, runeCNT]);

  // 刷新NFT、代币余额
  const getMoney = () => {
    if (!walletAddress) return;
    try {
      goldCNT?.methods
        .balanceOf(walletAddress)
        .call()
        .then((data) => {
          console.log("GOLD balanceOf ", walletAddress, " is: ", data);
          setGoldMoney(data);
        })
        .catch((err) => console.warn("Error Getting B", err));
      web3Origin.eth.getBalance(walletAddress).then((data) => {
        setEthBalance(data);
        console.log("ETH BALANCE", data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * mint前检查校验
   */
  const preCheck = async (data) => {
    try {
      // 获取签名
      const signData = await queryRunePreCheck({
        runesNum: data.number, // 申请符文的数量
        walletAddress: data.walletAddress,
      });
      // runesNum
      // walletAddress
      // sig  签名
      // timestamp  时间戳
      // runesId    申请ID
      return signData;
    } catch (error) {
      return null;
    }
  };

  /**
   * 合约交易
   * @param {Object} params - 交易参数
   * @param {string|number} params.walletAddress
   * @param {number} params.number - 购买数量
   * @returns {Promise<Object>}
   */
  const mint = async ({ walletAddress: wAddress, number }) => {
    console.log("Ready to mint NFT: ", number, " of unit price: ", RUNES_PRICE);
    if (isMinting) return;
    toast.closeAll();
    if (!wAddress) {
      toastError(t("runes.err_connect_wallet"));
      return;
    }

    if (!number) {
      toastError(t("runes.err_input_num"));
      return;
    }

    setIsMinting(number);

    const ethBalance = await web3Origin.eth.getBalance(walletAddress);
    console.log(ethBalance);

    try {
      // 判断限额是否够用
      const readySpend = new Big(number).mul(RUNES_PRICE).mul(COIN_UNIT);
      // 查询ETH余额
      if (readySpend.gt(ethBalance)) {
        toastError(t("runes.err_balance_not_enough"));
        setIsMinting(false);
        return;
      }
    } catch (error) {
      console.log("mint error", error);
      let msg = error.message;
      const [err, data] = error.message.split("Internal JSON-RPC error.\n");
      msg = err.substring(0, 0) + "...";
      try {
        if (data && data[0] === "{") {
          const errInfo = JSON.parse(data);
          msg = errInfo?.message || "";
          const [raw, detail] = msg.split("Error:");
          msg = detail || raw;
        }
      } catch (error) {
        console.log("message cannot parse");
        msg = "Internal JSON-RPC error";
      }
      toastError(t("runes.err_mint_fail") + msg);
      setIsMinting(false);
      throw error;
    }

    const signData = await preCheck({ walletAddress: wAddress, number });
    if (!signData) {
      toast.closeAll();
      toastError(t("runes.err_cannot_buy"), { isClosable: true });
      setIsMinting(false);
      return;
    }

    const { payTo, timestamp, runesNum, runesId, sig } = signData;

    try {
      // 判断限额是否够用
      const readySpend = new Big(number).mul(RUNES_PRICE).mul(COIN_UNIT);
      // 查询ETH余额
      if (readySpend.gt(ethBalance)) {
        toastError(t("runes.err_balance_not_enough"));
        setIsMinting(false);
        return;
      }
      // GAS估算
      console.log("estimateGas...");
      toastInfo(t("runes.info_gas_est"));
      const gasPrice = (await web3Origin.eth.getGasPrice()) || undefined;

      console.log("Paras", readySpend.toNumber(), payTo, timestamp, runesNum, runesId, sig, gasPrice);

      const gasLimit = await runeCNT.methods
        .runes(payTo, wAddress, timestamp, Number(runesNum), Number(runesId), sig)
        .estimateGas({ from: walletAddress, value: readySpend.toNumber() });
      console.log("gas info for buy:(limit, gasPrice)", gasLimit, gasPrice, "GWei");
      toastInfo(t("runes.info_ask_confirm"));

      return await runeCNT.methods
        .runes(payTo, wAddress, timestamp, Number(runesNum), Number(runesId), sig)
        .send({ from: wAddress, gasPrice, gas: gasLimit, value: readySpend.toNumber() })
        .on("transactionHash", function (txhash) {
          // 发起成功， 等待支付结果
          console.log("transactionHash, hash", txhash);
          toast({ title: t("runes.suc_waiting") });
          postRuneTxHash({ txhash, runesId: Number(runesId) });
        })
        .on("error", function (error, redeipt) {
          console.log("trading send error", error, redeipt);
          toastError(t("runes.err_start_fail"));
          setIsMinting(false);
        })
        .then(function (txData) {
          // 交易结果
          console.log("txData", txData);
          toast({ status: "success", title: t("runes.suc_trade") });
          getMoney();
          setIsMinting(false);
        });
    } catch (error) {
      console.log("mint error", error);
      let msg = error.message;
      const [err, data] = error.message.split("Internal JSON-RPC error.\n");
      msg = err.substring(0, 0) + "...";
      try {
        if (data && data[0] === "{") {
          const errInfo = JSON.parse(data);
          msg = errInfo?.message || "";
          const [raw, detail] = msg.split("Error:");
          msg = detail || raw;
        }
      } catch (error) {
        console.log("message cannot parse");
        msg = "Internal JSON-RPC error";
      }
      toastError(t("runes.err_mint_fail") + msg);
      setIsMinting(false);
      throw error;
    }
  };

  return {
    // 持有资产信息
    assets: { gold: goldMoney, ethBalance },
    mint,
    isMinting,
    mintStatusDisplay,
  };
};

export default useRuneTrade;
