import Big from "big.js";

/**
 * Coin unit price
 */
export const COIN_UNIT = new Big(1e18);

/**
 * GWei
 */
export const GWEI_UNIT = new Big(1e9);

/**
 * @deprecated please use {GWEI_UNIT} instead
 */
export const GEI_UNIT = new Big(1e9);
