import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Image, Text, VStack } from "@chakra-ui/react";
import VC_ICON from "./cv.svg";
import cls from "./Entry.module.scss";

const CVEntry = ({ title, iconUrl, link = "/mineTransferOut" }) => {
  const navigate = useNavigate();
  return (
    <Box className={cls.assetEntry} bg="gray.800" borderRadius="md" p="2" onClick={() => navigate(link)}>
      <VStack spacing={0}>
        <Image src={iconUrl} width="24" />
        <Text fontSize="16">{title}</Text>
      </VStack>
    </Box>
  );
};

export const entries = [
  {
    title: "CA2转出",
    iconUrl: VC_ICON,
  },
  {
    title: "CA转CA2",
    iconUrl: VC_ICON,
    link: "/mineTransfer?coin1=ca&coin2=ca2",
  },
  {
    title: "CA转SA",
    iconUrl: VC_ICON,
    link: "/mineTransfer?coin1=ca&coin2=da",
  },
  {
    title: "CA2转出SA",
    iconUrl: VC_ICON,
    link: "/mineTransfer?coin1=ca2&coin2=sa",
  },
];

export const CVEntries = () => {
  const entries = [
    {
      title: "CA2转出",
      iconUrl: VC_ICON,
    },
    {
      title: "CA转CA2",
      iconUrl: VC_ICON,
      link: "/mineTransfer?coin1=ca&coin2=ca2",
    },
    {
      title: "CA转SA",
      iconUrl: VC_ICON,
      link: "/mineTransfer?coin1=ca&coin2=da",
    },
    {
      title: "CA2转出SA",
      iconUrl: VC_ICON,
      link: "/mineTransfer?coin1=ca2&coin2=sa",
    },
  ];

  return entries.map((ele, index) => <CVEntry key={index} {...ele} />);
};

export default CVEntry;
