import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AddIcon, LinkIcon, SearchIcon } from "@chakra-ui/icons";
import Navbar from "_c/Navbar";
import cls from "./index.module.scss";
import PeopleItem from "./PeopleItem";
import NewUserList from "./NewUserList";
import { useNavigate } from "react-router-dom";
import UnSettledModal from "./UnSettledModal";
import request, { Method } from "_u/request";
import { stringify } from "qs";

function TeamPage(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const [name, setName] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [dataTree, setDataTree] = useState([[], [], []]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectMode, setSelectMode] = useState(false);
  const [unsettledCount, setUnsettledCount] = useState(0);

  useEffect(() => {
    ChangeToSelf();
    queryUnsettleCount();
  }, []);

  const queryUnsettleCount = async () => {
    try {
      const data = await request("gm/api/user/team?forWhat=ForBind");
      setUnsettledCount(data?.length || 0);
    } catch (error) {}
  };

  const doFilter = async (keyword) => {
    try {
      setIsPending(true);
      toast.closeAll();
      const data = await request(`gm/api/user/search?${stringify({ username: keyword || undefined })}`);
      // console.log(data);
      if (!data?.user?.username) {
        toast({ status: "error", title: t("team.msg_cannot_search") });
      } else {
        setTreeView(data);
        setName(""); // 查询完成，删除输入框内容
      }
    } catch (error) {
      console.warn(error);
    }
    setIsPending(false);
  };

  // 返回自身
  const ChangeToSelf = () => {
    setName("");
    doFilter();
  };

  const handleSearchKey = (ev) => {
    if (ev.isComposing || ev.keyCode === 229) return;
    if (ev.keyCode === 13) {
      doFilter(name);
    }
  };

  const setTreeView = (data) => {
    const { leftNode, rightNode, user } = data || {};
    const tempTree = [[], [], []];
    if (!(user && user.username)) {
      return setDataTree(tempTree);
    }
    tempTree[0][0] = user;
    if (leftNode) {
      tempTree[1][0] = leftNode.user;

      if (leftNode.leftNode) {
        tempTree[2][0] = leftNode.leftNode.user;
      }
      if (leftNode.rightNode) {
        tempTree[2][1] = leftNode.rightNode.user;
      }
    }

    if (rightNode) {
      tempTree[1][1] = rightNode.user;

      if (rightNode.leftNode) {
        tempTree[2][2] = rightNode.leftNode.user;
      }
      if (rightNode.rightNode) {
        tempTree[2][3] = rightNode.rightNode.user;
      }
    }

    setDataTree(tempTree);
  };

  const handlePeopleClick = (e, value, row, col) => {
    // 添加
    if (!value) {
      onOpen();
      setSelectMode({ row, col });
    } else {
      // 取消下钻
      // doFilter(value.username);
    }
  };

  const handleSelect = async (item, positionStyle) => {
    try {
      const { row, col } = selectMode || {};
      console.log(item, row, col);
      if (!item.userId) throw new Error(t("team.msg_data_error_check"));

      if (row === undefined && !positionStyle) {
        throw new Error(t("team.msg_data_error"));
      }
      const upUser = dataTree[row > 0 ? row - 1 : 0][Math.floor((col || 0) / 2)]; // 整数，上一级的userId col: 0,1,2,3 上级二分
      if (!upUser) throw new Error(t("team.msg_rel_fail"));
      const upUserId = upUser.userId;
      const userId = item.userId; // 被设定的用户ID
      const positionFlag = (col % 2) + 1; //  1（左区）、2（右区

      const data = await request("gm/api/user/bind_position", {
        method: Method.POST,
        body: {
          upUserId,
          userId,
          positionFlag: positionStyle ? undefined : positionFlag,
          positionStyle,
        },
      });
      if (data) {
        toast({ status: "success", title: t("team.msg_success") });
        doFilter(dataTree[0][0].username);
        queryUnsettleCount();
      } else {
        toast({ status: "info", title: t("team.msg_wrong") });
      }
    } catch (error) {
      toast({ status: "error", title: t("team.msg_fail") + (error.message || "") });
    }
  };

  const isSelfTop = dataTree[0][0]?.selfFlag; // 顶层节点是否是自己，如果是需要限制二级需要从左边添加

  return (
    <div className={cls.page}>
      <Navbar />
      <Box className={cls.cnt} m={6}>
        <InputGroup rounded>
          <InputLeftElement ml={2}>
            <SearchIcon />
          </InputLeftElement>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyUp={handleSearchKey}
            pl={12}
            pr={16}
            borderRadius="3xl"
            placeholder={t("team.search_pld")}
          ></Input>
          <InputRightElement onClick={() => doFilter(name)} mr={4}>
            {t("team.search")}
          </InputRightElement>
        </InputGroup>

        <HStack p={2} py={6} spacing={6} justify="space-between">
          <Button onClick={ChangeToSelf} variant="link" leftIcon={<LinkIcon color="#0c9ff6" />}>
            {t("team.back_self")}
          </Button>
          {/* <Button onClick={ChangeToLeftTail} variant="link" leftIcon={<ArrowLeftIcon color="#0c9ff6" />}>
            左区末位
          </Button> */}
        </HStack>

        <Box pos="relative">
          <HStack justify="center">
            <PeopleItem data={dataTree[0][0]} row={0} col={0} />
          </HStack>
          <HStack justify="center">
            <Box className={cls.line1L} />
            <Box className={cls.line1R} />
          </HStack>
          <HStack justify="space-between">
            <Center flex={1}>
              <PeopleItem data={dataTree[1][0]} row={1} col={0} onClick={handlePeopleClick} addable={!!dataTree[0][0]} />
            </Center>
            <Center flex={1}>
              <PeopleItem
                data={dataTree[1][1]}
                row={1}
                col={1}
                onClick={handlePeopleClick}
                addable={(!isSelfTop && dataTree[0][0]) || (isSelfTop && !!dataTree[1][0])}
              />
            </Center>
          </HStack>
          <HStack gap={0} justify="space-between">
            <HStack flex={1} justify="center">
              <Box className={cls.line2L} />
              <Box className={cls.line2R} />
            </HStack>
            <HStack flex={1} justify="center">
              <Box className={cls.line2L} />
              <Box className={cls.line2R} />
            </HStack>
          </HStack>
          <HStack spacing={2} justify="space-around">
            <PeopleItem data={dataTree[2][0]} row={2} col={0} onClick={handlePeopleClick} addable={dataTree[1][0]} />
            <PeopleItem data={dataTree[2][1]} row={2} col={1} onClick={handlePeopleClick} addable={dataTree[1][0]} />
            <PeopleItem data={dataTree[2][2]} row={2} col={2} onClick={handlePeopleClick} addable={dataTree[1][1]} />
            <PeopleItem data={dataTree[2][3]} row={2} col={3} onClick={handlePeopleClick} addable={dataTree[1][1]} />
          </HStack>

          {!!isPending && (
            <Flex className={cls.loading}>
              <Spinner />
            </Flex>
          )}
        </Box>

        <HStack justify="center" mt={6} p={6} py={3} bg="gray.800" borderRadius="xl">
          <VStack onClick={() => navigate("/mineRegister")}>
            <AddIcon fontSize="1.3rem" color="#0c9ff6" />
            <Text fontSize="sm">{t("team.register")}</Text>
          </VStack>
          {/* <VStack onClick={() => navigate("/mineSub")}>
            <UpDownIcon fontSize="1.3rem" color="#0c9ff6" />
            <Text fontSize="sm">子账户</Text>
          </VStack>
          <VStack onClick={() => navigate("/mineLink")}>
            <ExternalLinkIcon fontSize="1.3rem" color="#0c9ff6" />
            <Text fontSize="sm">关联账户</Text>
          </VStack> */}
        </HStack>
        <Box justify="center" mt={6} p={3} px={0}>
          <Button
            w="100%"
            // colorScheme="blue"
            onClick={() => {
              setSelectMode(true);
              onOpen();
            }}
          >
            {t("team.pending_list")}&nbsp;
            <Text color="#8a62ef">{unsettledCount}</Text>
          </Button>
        </Box>
      </Box>

      <NewUserList mt={4} p={6} pt={0} pb={6}></NewUserList>

      {/* <LeaderList mt={4} p={6} pt={0} pb={6} /> */}
      <UnSettledModal
        isOpen={isOpen}
        onClose={onClose}
        selectable={!!selectMode}
        isManual={selectMode?.row} // 未指定row，就是要用自动滑落方式，这个判断有效是因为row同时不可以是0，
        onSelect={handleSelect}
      />
      <VStack h="80px" />
    </div>
  );
}

export default TeamPage;
