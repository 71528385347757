import request, { Method } from "_u/request";
// import { stringify } from "qs";

/**
 * 投前检查
 */
export const queryMintPreCheck = () => {
  return request(`/gm/api/order/precheck`);
};

/**
 * 提交交易结果hash
 */
export const postTxHash = ({ txhash }) => {
  return request(`/gm/api/order/submit`, {
    method: Method.POST,
    body: { txhash },
  }).catch(console.warn);
};

/**
 * 铸造前检查
 */
export const queryRunePreCheck = (data) => {
  return request(`/gm/api/runes/sign`, {
    method: Method.POST,
    body: data,
  });
};

/**
 * 提交铸造交易结果hash
 */
export const postRuneTxHash = ({ txhash, runesId }) => {
  return request(`/gm/api/runes/submit`, {
    method: Method.POST,
    body: { txhash, runesId },
  }).catch(console.warn);
};
