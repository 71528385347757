import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { removeAccount } from "@/provider/web3";
import { ArrowUpDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import useUserInfo from "@/api/useUserInfo";
import { walletAuthLogout } from "_u/web3Login";
import request, { Method } from "_u/request";
import Navbar from "_c/Navbar";
import DMMC from "_icon/DMMC";
import OrderIcon from "_icon/OrderIcon";
import SettingIcon from "_icon/SettingIcon";
import { clearCachedProvider } from "_u/web3Util";
import GameIcon from "_icon/GameIcon";
import IMG_BG from "_img/trade-bg.jpg";
import IMG_MEDAL from "_img/medal.png";
import cls from "./index.module.scss";

const Entry = ({ onClick, icon, iconUrl, title, link }) => {
  const navigate = useNavigate();
  return (
    <HStack bg="gray.800" p={3} borderRadius="xl" align="center" onClick={onClick || (() => navigate(link))}>
      {icon || <Image src={iconUrl} />}
      <Text flex={1}>{title}</Text>
      <ChevronRightIcon />
    </HStack>
  );
};

const Page = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userInfo } = useUserInfo();

  const [unBinding, setUnBinding] = useState(false);
  const [dynamicData, setDynamicData] = useState({});

  useEffect(() => {
    initPageData();
  }, []);

  const initPageData = () => {
    request("gm/api/user/selfassets")
      .then((data) => {
        console.log(data);
        data && setDynamicData(data);
      })
      .catch(console.warn);
  };

  const logout = () => {
    removeAccount();
    clearCachedProvider();
    walletAuthLogout();
  };

  const unBindWallet = async () => {
    setUnBinding(true);
    try {
      toast.closeAll();
      const data = await request("gm/api/user/unbind_wallet", {
        method: Method.POST,
      });
      if (data) {
        toast({ title: t("mine.msg_sub_err"), status: "success" });
        await walletAuthLogout();
        navigate("/mineLogin");
      } else {
        toast({ title: t("mine.msg_sub_err"), status: "error" });
      }
    } catch (error) {
      toast({ title: t("mine.msg_sub_err") + ": " + error.message, status: "error" });
    }
    setUnBinding(false);
  };

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <VStack p="8" pt={12} mb={3} pos={"relative"} zIndex={1} align="center">
          {/* <Image src={IMG_LOGO} width={20} /> */}
          <Flex borderRadius="xl" p={2} bg="gray.800" align="center" justify="center">
            <DMMC fontSize="3em" />
          </Flex>
          <Flex align="center">
            <Heading as="h3" size="xl">
              {userInfo?.username || "CONTRIBUTOR"}
            </Heading>
            {!!dynamicData.marketNodeFlag && <Image ml={1} src={IMG_MEDAL} width={8} lineHeight={1} />}
          </Flex>
          {dynamicData.marketNodeFlag ? (
            <Text align="center" fontSize="sm" opacity={0.6}>
              {t("mine.medal_label")}
              {dynamicData.marketNodeId}
            </Text>
          ) : (
            <Text align="center" fontSize="sm" opacity={0.6}>
              &nbsp;
            </Text>
          )}
          <Text align="center" fontSize="sm" opacity={0.6}>
            {t("team.col_token")}:{dynamicData.tokenId}&nbsp;&nbsp;
            {t("mine.spread_username")}:{dynamicData.spreadUsername}
          </Text>
        </VStack>

        <Box p={6} pt={0} pb={8}>
          <Box mt={4} borderRadius="xl" bg="transparent">
            <StatGroup textAlign="center" pb="2" pt="2">
              <Stat>
                <StatNumber>
                  <Text as="span" fontSize="1em">
                    $
                  </Text>
                  {dynamicData.brokerageRewardTotalAmount || "--"}
                </StatNumber>
                <StatLabel>{t("mine.reward_total_amount")}</StatLabel>
              </Stat>
              <Stat>
                <StatNumber>
                  <Text as="span" fontSize="1em">
                    $
                  </Text>
                  {dynamicData.creditAvailable || "--"}
                </StatNumber>
                <StatLabel>{t("mine.credit_available")}</StatLabel>
              </Stat>
              <Stat>
                <StatNumber>
                  <Text as="span" fontSize="1em">
                    DM
                  </Text>
                  {dynamicData.dmAmount || "--"}
                </StatNumber>
                <StatLabel>{t("mine.lp_amount")}</StatLabel>
              </Stat>
            </StatGroup>

            <StatGroup textAlign="center" pb="2" pt="2">
              <Stat>
                <StatNumber fontSize="2xl">
                  <Text as="span" fontSize="1em">
                    $
                  </Text>
                  {parseFloat(dynamicData.leftAreaPrice) + parseFloat(dynamicData.rightAreaPrice) || "--"}
                </StatNumber>
                <StatLabel>{t("mine.stat_price_1")}</StatLabel>
              </Stat>
              <Stat>
                <StatNumber fontSize="2xl">
                  <Text as="span" fontSize="1em">
                    $
                  </Text>
                  {dynamicData.leftAreaPrice || "--"}
                </StatNumber>
                <StatLabel>{t("mine.stat_price_2")}</StatLabel>
              </Stat>
              <Stat>
                <StatNumber fontSize="2xl">
                  <Text as="span" fontSize="1em">
                    $
                  </Text>
                  {dynamicData.rightAreaPrice || "--"}
                </StatNumber>
                <StatLabel>{t("mine.stat_price_3")}</StatLabel>
              </Stat>
            </StatGroup>

            {/* 算力展示区 */}
            <StatGroup textAlign="center" pb="2" pt="2">
              <Stat>
                <StatNumber fontSize="2xl">
                  <Text as="span" fontSize="1em"></Text>
                  {dynamicData.selfPowerValue || "--"}
                </StatNumber>
                <StatLabel>{t("mine.self_power_value")}</StatLabel>
              </Stat>
            </StatGroup>

            <Box mt={12} p={4} pt={0}>
              <Button
                w="100%"
                bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
                color="white"
                onClick={() => navigate("/mineOTCRecord")}
                leftIcon={<OrderIcon fontSize={20} color="#fff" />}
              >
                {t("mine.asset_log")}
              </Button>

              <Button
                mt={6}
                w="100%"
                color="white"
                bgGradient="linear(to-l, #0c9ff6, #776bf0, #8a62ef)"
                onClick={() => navigate("/mineTransferOut")}
                leftIcon={<ArrowUpDownIcon fontSize={18} color="#fff" />}
              >
                {t("home.withdraw")}
              </Button>
              <Button
                mt={6}
                w="100%"
                color="white"
                bgGradient="linear(to-l,  #776bf0, #0c9ff6,#8a62ef)"
                onClick={() => navigate("/mineTransferDM")}
                leftIcon={<ArrowUpDownIcon fontSize={18} color="#fff" />}
              >
                 {t("transfer_out.sell_DM")}
              </Button>
              <Button
                mt={6}
                w="100%"
                color="white"
                bgGradient="linear(to-l, #0c9ff6, #776bf0, #8a62ef)"
                onClick={() => navigate("/mineTransferDMOut")}
                leftIcon={<ArrowUpDownIcon fontSize={18} color="#fff" />}
              >
                {t("transfer_out.withdraw_DM")}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box>
          {/* <Box bg="gray.700" pt="6" p="8" pb="6" pos={"relative"} zIndex={1}>
            <Box>
              <Heading size="md">财务记录</Heading>
              <Box mt={4} borderRadius="xl" bg="gray.700">
                <Grid templateRows="repeat(3, 1fr)" templateColumns="repeat(2, 1fr)" gap={2}>
                  <Entry title="价值分叉" icon={<OrderIcon fontSize={20} color="#3182CE" />} link="/mineASYSell" />
                  <Entry title="CA记录" icon={<OrderIcon fontSize={20} color="#3182CE" />} link="/mineOTCRecord?type=CA" />
                  <Entry title="CA2记录" icon={<OrderIcon fontSize={20} color="#3182CE" />} link="/mineOTCRecord?type=CA2" />
                  <Entry title="SA记录" icon={<OrderIcon fontSize={20} color="#3182CE" />} link="/mineOTCRecord?type=SA" />
                  <Entry title="分叉记录" icon={<OrderIcon fontSize={20} color="#3182CE" />} link="/mineNetwork" />
                  <Entry title="充值记录" icon={<OrderIcon fontSize={20} color="#3182CE" />} link="/mineImport" />
                </Grid>
              </Box>
            </Box>
          </Box> */}

          <Box mt={2} bg="gray.700" pt="6" p="8" pb="12" pos={"relative"} zIndex={1}>
            <Box>
              <Heading size="md">{t("mine.setting")}</Heading>
              <Box mt={4} borderRadius="xl" bg="gray.700">
                <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(1, 1fr)" gap={3}>
                  <Entry title={t("mine.mod_code")} icon={<SettingIcon fontSize={20} color="#9F7AEA" />} link="/mineChange" />
                  {/* <Entry title="验签绑定" icon={<SettingIcon fontSize={20} color="#3182CE" />} /> */}
                  <Entry title={t("mine.unbind")} onClick={onOpen} icon={<SettingIcon fontSize={20} color="#ED8936" />} />
                  <Entry title={t("mine.logout")} onClick={logout} icon={<GameIcon fontSize={20} color="#F56565" />} />
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>

        <VStack h="60px" bg="gray.800" />
      </Box>

      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent w="85vw">
          <ModalHeader>
            <Heading fontSize="2xl">{t("mine.unbind")}</Heading>
          </ModalHeader>
          <ModalBody>
            <Box bg="gray.800" p={6} color="#E53E3E">
              {t("mine.unbind_txt_1")}
              <br />
              {t("mine.unbind_txt_2")}
            </Box>
            <HStack my={6} justify="space-between">
              <Button w="100%" onClick={onClose}>
                {t("mine.cancel")}
              </Button>
              <Button
                isLoading={unBinding}
                isDisabled={unBinding}
                variant="solid"
                colorScheme="blue"
                bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
                w="100%"
                onClick={unBindWallet}
              >
                {t("mine.confirm")}
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Page;
