import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  FormHelperText,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";
import IMG_BG from "_img/trade-bg.jpg";
import cls from "./index.module.scss";
import useDMSaleTrade from "@/hoc/useDMSaleTrade";
import { useAtomValue } from "jotai";
import { walletAccountAtom } from "@/provider/web3";
import { COIN_UNIT } from "_cfg/numeric";
import Big from "big.js";
// import NFTTrade from "./NFTTrade";

const Page = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const currentWalletAddress = useAtomValue(walletAccountAtom);
  const { assets, isMinting, mint, rate, mintStatusDisplay } = useDMSaleTrade(currentWalletAddress);
  const [creditNum, setCreditNum] = useState(0);
  const [pending, setPending] = useState(false);
  const [maxNum, setMaxNum] = useState(0);
  const [minNum, setMinNum] = useState(0);

  const contVal = useMemo(() => new Big(assets.gold || 0).div(COIN_UNIT).toFixed(2), [assets.gold]);

  useEffect(() => {
    const max = parseInt(contVal);
    setMaxNum(max);
    setMinNum(1);
    setCreditNum(Math.min(1, contVal));
  }, [contVal]);

  const rateDisplay = useMemo(() => {
    return `1 USDT ≈ ${new Big(rate).div(10000).toFixed(0)} DM`;
  }, [rate]);

  const handleSubmit = async () => {
    if (pending) return;
    setPending(true);
    try {
      if (!creditNum) {
        toast({ status: "error", title: t("transfer_out.msg_empty_num") });
      } else if (maxNum - creditNum < 0) {
        toast({ status: "error", title: t("transfer_out.msg_num_over") });
      } else {
        mint({ number: creditNum, walletAddress: currentWalletAddress });
        // toast({ status: "success", title: t("transfer_out.msg_suc") });
      }
    } catch (error) {
      toast({ status: "success", title: t("transfer_out.msg_err") + " " + error.message });
    }
    setPending(false);
  };

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mb={8}>
            <NavBackIcon />
            <Heading as="h2" size="xl" className={cls.colorful}>
              {t("transfer_out.sell_DM")}
            </Heading>
          </Flex>
        </Box>

        <Box className={cls.cnt} bg="gray.800" p="1rem 2rem">
          <FormControl>
            <FormLabel>
              {t("transfer_out.sell_DM")}({maxNum ? ` ${minNum} ~ ${maxNum}` : ""}) DM
            </FormLabel>
            <NumberInput
              flex={1}
              step={1}
              min={minNum}
              max={maxNum}
              value={creditNum}
              onChange={(v) => (!v || /^[0-9]\d*$/.test(v)) && setCreditNum(Number(v))}
            >
              <NumberInputField placeholder={t("trade.pld_num")} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormHelperText>{rateDisplay}</FormHelperText>
          </FormControl>

          <Button
            isLoading={isMinting}
            isDisabled={isMinting || !maxNum}
            onClick={handleSubmit}
            mt={8}
            borderRadius="full"
            letterSpacing={1}
            width="100%"
            size="lg"
            color="white"
            bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
            loadingText={<Text fontSize="sm">{t("transfer_out.submitting")}</Text>}
          >
            {t("transfer_out.sell_DM")}
          </Button>

          <VStack h="60px" />
        </Box>
      </Box>
    </div>
  );
};

export default Page;
