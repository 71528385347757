import React from "react";
import { Box, Button, Flex, Heading, Image, Link, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import Navbar from "_c/Navbar";
import IMG_BG from "_img/otc-bg.jpg";
import cls from "./index.module.scss";
import { NavBackIcon } from "_c/Navbar/NavBackIcon";

const Page = () => {
  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={12} pos={"relative"} zIndex={1}>
          <Flex align="center" mt={8}>
            <NavBackIcon />
            <Heading as="h2" size="lg">
              关联账户
            </Heading>
          </Flex>

          <SimpleGrid mt={6} columns={1}>
            <Link href="/mineAddLink">
              <Button size="lg" variant="solid" w="100%" colorScheme="blue">
                新增关联账户
              </Button>
            </Link>
          </SimpleGrid>
        </Box>
        <Box bg="gray.800" py={6}>
          <Box bg="gray.600" borderRadius="md" p={3} m={6} mt={3}>
            <Flex justify="space-between" align="center" pb={2}>
              <Text fontSize="1rem">白名单: WW**88</Text>
              <Button size="xs" colorScheme="blue" variant="solid" borderRadius="4px">
                切换至该账户
              </Button>
            </Flex>
            <Box bg="gray.800" p={3}>
              <Flex>
                <Text opacity={0.8}>账户资产：</Text>
                <Text> $ {Number(180000).toLocaleString()}</Text>
              </Flex>
              <Flex mt={2} justify="space-between">
                <Box>
                  <Text opacity={0.8}>账户净量进度：</Text>
                  <Text>50/200</Text>
                </Box>
                <Box>
                  <Text opacity={0.8}>账户动量进度：</Text>
                  <Text>300/1000</Text>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Box>
        <VStack h="60px" />
      </Box>
    </div>
  );
};

export default Page;
