import { useTranslation } from "react-i18next";
import { Box, Heading } from "@chakra-ui/react";
import CustomInfiniteDataTable from "_c/CustomDataTable/Infinite";
import React from "react";

const NewUserList = ({ children, ...props }) => {
  const { t } = useTranslation();
  const columns = [
    { title: t("team.col_username"), key: "username", align: "center" },
    { title: t("team.col_main"), key: "leftAreaPrice", align: "center" },
    { title: t("team.col_potential"), key: "rightAreaPrice", align: "center" },
    { title: t("team.col_token"), key: "tokenId", align: "center" },
  ];

  return (
    <Box bg="gray.800" {...props}>
      <Heading py={4} fontSize="xl">
        {t("team.new_user")}
      </Heading>
      <Box mx={-3}>
        <CustomInfiniteDataTable
          api="gm/api/user/team"
          dataSource={[]}
          // dataSource={[{ username: "A**x", main: 1000, potential: 200, package: 600 }]}
          columns={columns}
        />
      </Box>

      {children}
    </Box>
  );
};

export default NewUserList;
