import { CURRENT_IS_TEST } from "./env";

/**
 * BSC测试网络地址
 */
const BSC_TEST_NETWORK = {
  chainId: "0x61", // 97
  chainName: "Binance Smart Chain Testnet",
  nativeCurrency: {
    name: "tBNB",
    symbol: "tBNB",
    decimals: 18,
  },
  rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
  blockExplorerUrls: ["https://testnet.bscscan.com/"],
};
/**
 * BSC公网网络地址
 */
const BSC_MAIN_NETWORK = {
  chainId: "0x38", // 56
  chainName: "Binance Smart Chain Mainnet",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: ["https://bsc-dataseed1.binance.org"],
  blockExplorerUrls: ["https://bscscan.com/"],
};

/**
 * BSC网络地址
 */
export const BSC_NETWORK = CURRENT_IS_TEST ? BSC_TEST_NETWORK : BSC_MAIN_NETWORK;
