import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, HStack } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import cls from "./Entry.module.scss";

const Entry = ({ title, icon, value, link, direction }) => {
  const navigate = useNavigate();
  if (direction === "row") {
    return (
      <Box className={cls.assetEntry} bg="gray.700" borderRadius="md" p="2" onClick={() => link && navigate(link)}>
        <HStack justify="space-between" spacing={1} fontSize={14} lineHeight={2}>
          {icon}
          <Box flex={1} ml={2}>
            <Box lineHeight={1.4}>{title}</Box>
          </Box>
          <Box fontSize="xl">{value}</Box>
          {!!link && <ChevronRightIcon fontSize="20" opacity={0.6} />}
        </HStack>
      </Box>
    );
  }
  return (
    <Box className={cls.assetEntry} bg="gray.700" borderRadius="md" p="2" onClick={() => link && navigate(link)}>
      <HStack justify="space-between" spacing={1} fontSize={12} lineHeight={2}>
        <Box alignSelf="flex-start">{icon}</Box>
        <Box flex={1}>
          <Box lineHeight={1.4}>{title}</Box>
          <Box fontSize="xl">{value}</Box>
        </Box>
        {!!link && <ChevronRightIcon fontSize="20" opacity={0.6} />}
      </HStack>
    </Box>
  );
};

export default Entry;
